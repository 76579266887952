import { Routes, Route, BrowserRouter as Router, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Cart from '../pages/Cart';
import Profile from '../pages/Profile';
import Delivery from '../pages/Delivery';
import OrderSummary from '../pages/OrderSummary';
import MakePayment from '../pages/MakePayment';
import { AnimatePresence } from "framer-motion"
import OrderStatus from '../pages/OrderStatus';
import Authentication from '../pages/Authentication';
import FullPageLoader from '../components/FullPageLoader';
import AlertBox from '../components/AlertBox';
import OrderDetails from '../pages/OrderDetails';
import Categories from '../pages/Categories';
import ItemDetail from '../pages/ItemDetail';
import Header from './Header';
import CartHolder from './CartHolder';
import AboutUs from '../pages/AboutUs';
import Recipies from '../pages/Recipies';
import ContactUs from '../pages/ContactUs';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import RefundPolicy from '../pages/RefundPolicy';
import ApplyCoupon from '../pages/ApplyCoupon';
import Landing from '../pages/Landing';
import { useContext } from 'react';
import { CommonContext } from '../contexts/CommonContext';
import Stores from '../pages/Stores';
import RecipieDetail from '../pages/RecipieDetail';
import AllCategories from '../pages/AllCategories';
import MyAddresses from '../pages/MyAddresses';
import EditAddress from '../pages/EditAddress';
import Faqs from '../pages/Faqs';
import AdminOrder from '../pages/AdminOrder';
import AdminBlogs from '../pages/AdminBlogs';
import Blogs from '../pages/Blogs';
import BlogPost from '../pages/BlogPost';
import NewBlogPost from '../pages/NewBlogPost';
import EditBlogPost from '../pages/EditBlogPost';

function AnimatedRoutes() {
  
  const location = useLocation()
  const {isDesktop}  = useContext(CommonContext)

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>

        <Route element={<FullPageLoader /> } >

          <Route element={<AlertBox />}>

  
            <Route element={<Header />}>
              <Route element={<CartHolder />}>
                <Route path="/" element={isDesktop ? < Landing /> : <Home />}/>
                <Route path="/home" element={<Home />}/>
                <Route path="/home/:id" element={<Home />}/>
                <Route path="/categories/:id" element={<Categories />}/>
              </Route>

              <Route path="/products/:id" element={<ItemDetail />}/>
              <Route path="/cart" element={<Cart />}/>
              <Route path="/profile" element={<Profile />}/>
              <Route path="/delivery" element={<Delivery />}/>
              <Route path="/orderSummary" element={<OrderSummary />}/>
              <Route path="/makePayment" element={<MakePayment />}/>
              <Route path="/orderStatus" element={<OrderStatus />}/>
              <Route path="/auth" element={<Authentication />}/>
              <Route path="/orderDetails" element={<OrderDetails />}/>
              <Route path="/applyCoupon" element={<ApplyCoupon />}/>
              <Route path="/blogs" element={<Blogs />}/>
              <Route path="/blogs/:urlId" element={<BlogPost />}/>
              <Route path="/admin-blogs" element={<AdminBlogs />}/>
              <Route path="/blogs/new" element={<NewBlogPost />}/>
              <Route path="/blogs/edit/:postId" element={<EditBlogPost />}/>
              <Route path="/recipieDetails/:id" element={<RecipieDetail />}/>

              <Route path="/aboutUs" element={<AboutUs />}/>
              <Route path="/allCategories" element={<AllCategories />}/>
              <Route path="/stores" element={<Stores />}/>
              <Route path="/recipies" element={<Recipies />}/>
              <Route path="/contactUs" element={<ContactUs />}/>
              <Route path="/termsAndConditions" element={<TermsAndConditions />}/>
              <Route path="/privacyPolicy" element={<PrivacyPolicy />}/>
              <Route path="/refundPolicy" element={<RefundPolicy />}/>
              <Route path="/myAddresses" element={<MyAddresses />}/>
              <Route path="/editAddress" element={<EditAddress />}/>
              <Route path="/faqs" element={<Faqs />}/>
              <Route path="/admin-order" element={<AdminOrder />}/>
            </Route>
          </Route>
          </Route>
        
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes;
