import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getBlogData, logAction } from '../services/api';
import ComponentLoader from '../components/ComponentLoader';
import { 
  Box, 
  Typography, 
  Button, 
  Divider, 
  Paper, 
  Container, 
  CardMedia 
} from '@mui/material';
import NavHeader from '../components/NavHeader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet';

// Note: We've switched from Markdown to TinyMCE rich text editor

function BlogPost() {
  const { urlId } = useParams();
  const [loading, setLoading] = useState(true);
  const [blogPost, setBlogPost] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const post = await getBlogData(urlId);
        setBlogPost(post);
        logAction('VIEW_BLOG_POST', urlId);
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setError("Blog post not found or an error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [urlId]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Function to strip HTML tags for meta descriptions
  const stripHtml = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>?/gm, '');
  };

  // Get a clean description for meta tags
  const getMetaDescription = (content, maxLength = 160) => {
    const plainText = stripHtml(content);
    if (plainText.length <= maxLength) return plainText;
    return plainText.substring(0, maxLength) + '...';
  };
  
  // Get SEO title or fallback to post title
  const getSeoTitle = () => {
    if (blogPost?.seo?.title) return blogPost.seo.title;
    return blogPost?.title || '';
  };
  
  // Get SEO description or fallback to generated description
  const getSeoDescription = () => {
    if (blogPost?.seo?.description) return blogPost.seo.description;
    return getMetaDescription(blogPost?.content || '');
  };
  
  // Get SEO keywords
  const getSeoKeywords = () => {
    return blogPost?.seo?.keywords || '';
  };

  return (
    <Box sx={{ padding: '4vw', marginTop: '7vh', paddingBottom: '8vh' }}>
      {loading ? (
        <ComponentLoader />
      ) : error ? (
        <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
          <Button 
            variant="contained" 
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/blogs')}
            sx={{ marginTop: '20px' }}
          >
            Back to Blogs
          </Button>
        </Box>
      ) : (
        <>
          <Helmet>
            <title>{getSeoTitle()}</title>
            <meta name="description" content={getSeoDescription()} />
            {getSeoKeywords() && <meta name="keywords" content={getSeoKeywords()} />}
            <meta property="og:title" content={getSeoTitle()} />
            <meta property="og:description" content={getSeoDescription()} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            {blogPost.coverImage && <meta property="og:image" content={blogPost.coverImage} />}
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          
          <NavHeader />
          
          <Container maxWidth="md">
            <Button 
              variant="outlined" 
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate('/blogs')}
              sx={{ marginBottom: '20px' }}
            >
              Back to Blogs
            </Button>
            
            <Paper elevation={2} sx={{ padding: '32px', marginBottom: '32px' }}>
              {blogPost.coverImage && (
                <CardMedia
                  component="img"
                  image={blogPost.coverImage}
                  alt={blogPost.title}
                  sx={{ 
                    objectFit: 'cover',
                    borderRadius: 1,
                    mb: 3,
                    maxHeight: '400px',
                    width: '100%'
                  }}
                />
              )}
              
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                {blogPost.title}
              </Typography>
              
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                By {blogPost.author} • {formatDate(blogPost.createdAt)}
                {blogPost.updatedAt && blogPost.updatedAt !== blogPost.createdAt && 
                  ` • Updated: ${formatDate(blogPost.updatedAt)}`}
              </Typography>
              
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              
              <div 
                dangerouslySetInnerHTML={{ __html: blogPost.content }} 
                style={{ 
                  lineHeight: 1.6,
                  '& img': {
                    maxWidth: '100%',
                    height: 'auto'
                  }
                }}
              />
            </Paper>
          </Container>
        </>
      )}
    </Box>
  );
}

export default BlogPost; 