import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBlogData, updateBlogPost, checkUrlIdExists, logAction, uploadImage } from '../services/api';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  Divider, 
  Grid,
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import NavHeader from '../components/NavHeader';
import { Editor } from '@tinymce/tinymce-react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageIcon from '@mui/icons-material/Image';
import { Helmet } from 'react-helmet';

function EditBlogPost() {
  const { postId } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [urlId, setUrlId] = useState('');
  const [originalUrlId, setOriginalUrlId] = useState('');
  const [urlError, setUrlError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState('');
  // Cover image states
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  // SEO fields
  const [seoTitle, setSeoTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [keywords, setKeywords] = useState('');
  
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);

  // Load blog post data
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        // First, get the blog post by ID directly from Firestore
        const docRef = doc(db, `blogPosts/${postId}`);
        const docSnap = await getDoc(docRef);
        
        if (!docSnap.exists()) {
          setLoadError('Blog post not found');
          return;
        }
        
        const post = { id: docSnap.id, ...docSnap.data() };
        
        setTitle(post.title || '');
        setContent(post.content || '');
        setAuthor(post.author || '');
        setUrlId(post.urlId || '');
        setOriginalUrlId(post.urlId || '');
        
        // Load cover image if exists
        if (post.coverImage) {
          setCoverImageUrl(post.coverImage);
          setImagePreview(post.coverImage);
        }
        
        // Load SEO data if it exists
        if (post.seo) {
          setSeoTitle(post.seo.title || '');
          setMetaDescription(post.seo.description || '');
          setKeywords(post.seo.keywords || '');
        } else {
          // Set default meta description from content
          const plainText = post.content ? post.content.replace(/<[^>]*>?/gm, '') : '';
          if (plainText) {
            setMetaDescription(plainText.substring(0, 155) + (plainText.length > 155 ? '...' : ''));
          }
        }
        
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setLoadError('Failed to load blog post data');
      } finally {
        setLoading(false);
      }
    };

    if (postId) {
      fetchBlogPost();
    }
  }, [postId]);

  // URL validation
  useEffect(() => {
    if (!urlId || urlId === originalUrlId) {
      setUrlError('');
      return;
    }

    // Validate format (alphanumeric and hyphens only)
    const isValidFormat = /^[a-z0-9-]+$/.test(urlId);
    if (!isValidFormat) {
      setUrlError('URL ID can only contain lowercase letters, numbers, and hyphens.');
      return;
    }

    // Check if URL already exists (debounced)
    const timer = setTimeout(async () => {
      try {
        const exists = await checkUrlIdExists(urlId);
        if (exists) {
          setUrlError('This URL ID is already taken. Please choose another one.');
        } else {
          setUrlError('');
        }
      } catch (error) {
        console.error('Error checking URL ID:', error);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [urlId, originalUrlId]);

  // Auto-generate meta description from content
  useEffect(() => {
    if (content && !metaDescription) {
      const plainText = content.replace(/<[^>]*>?/gm, '');
      if (plainText.length > 0) {
        setMetaDescription(plainText.substring(0, 155) + (plainText.length > 155 ? '...' : ''));
      }
    }
  }, [content, metaDescription]);

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if file is an image
      if (!file.type.match('image.*')) {
        setFormErrors({ ...formErrors, coverImage: 'Please select an image file (jpg, png, etc.)' });
        return;
      }
      
      // Check file size (limit to 2MB)
      if (file.size > 2 * 1024 * 1024) {
        setFormErrors({ ...formErrors, coverImage: 'Image size should be less than 2MB' });
        return;
      }

      setCoverImage(file);
      setFormErrors({ ...formErrors, coverImage: undefined });
      
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setCoverImage(null);
    setImagePreview('');
    setCoverImageUrl('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = {};
    if (!title.trim()) errors.title = 'Title is required';
    if (!content.trim()) errors.content = 'Content is required';
    if (!author.trim()) errors.author = 'Author is required';
    if (!urlId.trim()) errors.urlId = 'URL ID is required';
    if (!metaDescription.trim()) errors.metaDescription = 'Meta description is required for SEO';
    if (!coverImage && !coverImageUrl) errors.coverImage = 'Cover image is required';
    
    if (Object.keys(errors).length > 0 || urlError) {
      setFormErrors(errors);
      return;
    }

    setIsSubmitting(true);
    
    try {
      // Determine if we need to upload a new cover image
      let finalCoverImageUrl = coverImageUrl;
      
      if (coverImage) {
        setUploadingImage(true);
        try {
          // Generate a unique filename using timestamp and original filename
          const timestamp = new Date().getTime();
          const fileName = `cover_${timestamp}_${coverImage.name}`;
          
          finalCoverImageUrl = await uploadImage(
            coverImage, 
            coverImage, 
            fileName, 
            'blog-images'
          );
        } catch (error) {
          console.error('Error uploading image:', error);
          setSubmitError('Failed to upload cover image. Please try again.');
          setIsSubmitting(false);
          setUploadingImage(false);
          return;
        } finally {
          setUploadingImage(false);
        }
      }
      
      const blogData = {
        title,
        content,
        author,
        urlId,
        coverImage: finalCoverImageUrl,
        seo: {
          title: seoTitle || title, // Use main title if SEO title not provided
          description: metaDescription,
          keywords: keywords
        }
      };
      
      await updateBlogPost(postId, blogData);
      logAction('UPDATE_BLOG_POST', postId);
      setShowSuccessAlert(true);
      
      // Check if URL ID was changed
      if (urlId !== originalUrlId) {
        setTimeout(() => {
          navigate(`/blogs/${urlId}`);
        }, 1500);
      } else {
        setTimeout(() => {
          navigate(`/blogs/${urlId}`);
        }, 1500);
      }
    } catch (error) {
      console.error('Error updating blog post:', error);
      setSubmitError(error.message || 'An error occurred while updating the blog post.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUrlIdChange = (e) => {
    // Convert to lowercase and replace spaces with hyphens
    const value = e.target.value.toLowerCase().replace(/\s+/g, '-');
    setUrlId(value);
  };

  const handleCancel = () => {
    navigate('/admin-blogs');
  };

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (loadError) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh',
          gap: 2
        }}
      >
        <Alert severity="error">{loadError}</Alert>
        <Button variant="contained" onClick={() => navigate('/admin-blogs')}>
          Back to Blog List
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '4vw', marginTop: '7vh', paddingBottom: '8vh' }}>
      <Helmet>
        <title>Edit Blog Post | Admin</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      
      <NavHeader />

      <Typography variant="h4" component="h1" gutterBottom>
        Edit Blog Post
      </Typography>

      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={3000}
        onClose={() => setShowSuccessAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setShowSuccessAlert(false)}>
          Blog post updated successfully!
        </Alert>
      </Snackbar>

      {submitError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {submitError}
        </Alert>
      )}

      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!!formErrors.title}
                helperText={formErrors.title}
                disabled={isSubmitting}
                required
              />
            </Grid>
            
            {/* Cover Image Upload Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Cover Image <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Box 
                sx={{ 
                  border: '1px dashed',
                  borderColor: formErrors.coverImage ? 'error.main' : 'grey.400',
                  borderRadius: 1,
                  p: 2,
                  textAlign: 'center',
                  backgroundColor: 'background.paper',
                  position: 'relative'
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCoverImageChange}
                  style={{ display: 'none' }}
                  id="cover-image-upload"
                  ref={fileInputRef}
                  disabled={isSubmitting || uploadingImage}
                />
                
                {imagePreview ? (
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      component="img"
                      src={imagePreview}
                      alt="Cover preview"
                      sx={{
                        maxWidth: '100%',
                        maxHeight: '200px',
                        objectFit: 'contain',
                        mb: 2
                      }}
                    />
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleRemoveImage}
                      disabled={isSubmitting || uploadingImage}
                      sx={{ position: 'absolute', top: 0, right: 0, minWidth: '30px', p: '4px' }}
                    >
                      ×
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <ImageIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 1 }} />
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                      {uploadingImage ? 'Uploading...' : 'Drag & drop an image here, or click to select one'}
                    </Typography>
                  </Box>
                )}
                
                <label htmlFor="cover-image-upload">
                  <Button
                    variant="contained"
                    component="span"
                    disabled={isSubmitting || uploadingImage}
                    startIcon={<ImageIcon />}
                  >
                    {imagePreview ? 'Change Image' : 'Select Image'}
                  </Button>
                </label>
                
                {formErrors.coverImage && (
                  <FormHelperText error sx={{ mt: 1 }}>
                    {formErrors.coverImage}
                  </FormHelperText>
                )}
                
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                  Recommended size: 1200×630 pixels (16:9 ratio). Maximum file size: 2MB.
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                error={!!formErrors.author}
                helperText={formErrors.author}
                disabled={isSubmitting}
                required
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!urlError || !!formErrors.urlId}>
                <InputLabel htmlFor="url-id">URL ID</InputLabel>
                <OutlinedInput
                  id="url-id"
                  value={urlId}
                  onChange={handleUrlIdChange}
                  label="URL ID"
                  placeholder="my-blog-post-url"
                  disabled={isSubmitting}
                  required
                />
                <FormHelperText>
                  {urlError || formErrors.urlId || "This will be used in the blog post's URL. Use lowercase letters, numbers, and hyphens only."}
                </FormHelperText>
              </FormControl>
            </Grid>
            
            {/* SEO Settings Accordion */}
            <Grid item xs={12}>
              <Accordion defaultExpanded sx={{ mb: 3 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="seo-settings-content"
                  id="seo-settings-header"
                >
                  <Typography variant="h6">SEO Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="SEO Title (Optional)"
                        placeholder="Leave blank to use the main title"
                        value={seoTitle}
                        onChange={(e) => setSeoTitle(e.target.value)}
                        helperText="Customize the title that appears in search engines (55-60 characters recommended)"
                        disabled={isSubmitting}
                        InputProps={{ sx: { fontFamily: 'monospace' } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Meta Description"
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                        error={!!formErrors.metaDescription}
                        helperText={formErrors.metaDescription || "Brief description that appears in search results (150-160 characters recommended)"}
                        required
                        multiline
                        rows={3}
                        disabled={isSubmitting}
                        InputProps={{ 
                          sx: { fontFamily: 'monospace' },
                          inputProps: { maxLength: 160 }
                        }}
                      />
                      <Typography variant="caption" color="text.secondary" sx={{ display: 'block', textAlign: 'right', mt: 1 }}>
                        {metaDescription.length}/160 characters
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Keywords"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        helperText="Comma-separated keywords for search engines"
                        disabled={isSubmitting}
                        InputProps={{ sx: { fontFamily: 'monospace' } }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              {formErrors.content && (
                <FormHelperText error={true} sx={{ mb: 1 }}>
                  {formErrors.content}
                </FormHelperText>
              )}
              <Editor
                apiKey="x6ujddo6ca6flg98pse3j18xapaafsr3mpxwyjjg09pyrkn2"
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={content}
                value={content}
                onEditorChange={(newContent) => setContent(newContent)}
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help | link image media table',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  promotion: false,
                  branding: false,
                  statusbar: false,
                  readonly: isSubmitting
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
                disabled={isSubmitting || !!urlError}
              >
                {isSubmitting ? 'Saving...' : 'Update Blog Post'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default EditBlogPost;