import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllBlogPosts, deleteBlogPost, logAction } from '../services/api';
import ComponentLoader from '../components/ComponentLoader';
import { 
  Box, 
  Button, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  IconButton, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  CardActions,
  TextField,
  Paper,
  Container,
  CardMedia,
  Divider
} from '@mui/material';
import NavHeader from '../components/NavHeader';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import { Helmet } from 'react-helmet';

function AdminBlogs() {
  const [loading, setLoading] = useState(true);
  const [blogPosts, setBlogPosts] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'haneethccc') {
      setIsAuthenticated(true);
      localStorage.setItem('adminBlogAuth', 'true');
      fetchBlogPosts();
    } else {
      setPasswordError('Incorrect password. Please try again.');
    }
  };

  const fetchBlogPosts = async () => {
    try {
      const posts = await getAllBlogPosts();
      setBlogPosts(posts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    logAction('VIEW_ADMIN_BLOG_LIST');
    
    // Check if admin is already authenticated
    const isAuth = localStorage.getItem('adminBlogAuth') === 'true';
    if (isAuth) {
      setIsAuthenticated(true);
      fetchBlogPosts();
    } else {
      setLoading(false);
    }
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Function to strip HTML tags and get plain text
  const stripHtml = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>?/gm, '');
  };

  const getSnippet = (content, maxLength = 100) => {
    const plainText = stripHtml(content || '');
    if (plainText.length <= maxLength) return plainText;
    return plainText.substring(0, maxLength) + '...';
  };

  const handleEditClick = (e, post) => {
    e.stopPropagation(); // Prevent card click event
    navigate(`/blogs/edit/${post.id}`);
  };

  const handleDeleteClick = (e, post) => {
    e.stopPropagation(); // Prevent card click event
    setPostToDelete(post);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!postToDelete) return;
    
    setIsDeleting(true);
    try {
      await deleteBlogPost(postToDelete.id);
      
      // Update the UI by removing the deleted post
      setBlogPosts(prevPosts => prevPosts.filter(post => post.id !== postToDelete.id));
      
      setAlertMessage('Blog post deleted successfully!');
      setAlertSeverity('success');
      setShowAlert(true);
      
      logAction('DELETE_BLOG_POST', postToDelete.id);
    } catch (error) {
      console.error("Error deleting blog post:", error);
      setAlertMessage('Failed to delete blog post. Please try again.');
      setAlertSeverity('error');
      setShowAlert(true);
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
      setPostToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setPostToDelete(null);
  };

  // If not authenticated, show password form
  if (!isAuthenticated) {
    return (
      <Box sx={{ padding: '4vw', marginTop: '7vh', paddingBottom: '8vh' }}>
        <Helmet>
          <title>Blog Admin | Country Chicken Co</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        
        <NavHeader />
        
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Typography variant="h5" component="h1" gutterBottom>
              Administrator Access
            </Typography>
            <Typography variant="body1" gutterBottom>
              This area is restricted. Please enter your password to continue.
            </Typography>
            
            <Box component="form" onSubmit={handlePasswordSubmit} sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                sx={{ mb: 2 }}
              />
              
              <Button
                type="submit"
                variant="contained"
                fullWidth
              >
                Login
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '4vw', marginTop: '7vh', paddingBottom: '8vh' }}>
      <Helmet>
        <title>Blog Admin | Country Chicken Co</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      
      <NavHeader />
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1">
          Blog Administration
        </Typography>
        
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={() => navigate('/blogs/new')}
        >
          New Blog Post
        </Button>
      </Box>

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowAlert(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the blog post "{postToDelete?.title}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} disabled={isDeleting}>
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            disabled={isDeleting}
            autoFocus
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <ComponentLoader />
      ) : blogPosts.length === 0 ? (
        <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
          No blog posts available yet. Click "New Blog Post" to create your first post!
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {blogPosts.map((post) => (
            <Grid item xs={12} md={6} lg={4} key={post.id}>
              <Card 
                elevation={2}
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  cursor: 'pointer'
                }}
                onClick={() => navigate(`/blogs/${post.urlId}`)}
              >
                {post.coverImage ? (
                  <CardMedia
                    component="img"
                    height="180"
                    image={post.coverImage}
                    alt={post.title}
                    sx={{ objectFit: 'cover' }}
                  />
                ) : (
                  <Box 
                    sx={{ 
                      height: 120, 
                      backgroundColor: 'grey.200', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <ImageIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
                    <Typography variant="body2" color="text.secondary">
                      No cover image
                    </Typography>
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="h2" gutterBottom noWrap>
                    {post.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    By {post.author} • {formatDate(post.createdAt)}
                    {post.updatedAt && post.updatedAt !== post.createdAt && 
                      ` • Updated: ${formatDate(post.updatedAt)}`}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {getSnippet(post.content)}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <IconButton 
                    size="small" 
                    color="primary" 
                    onClick={(e) => handleEditClick(e, post)}
                    title="Edit Blog Post"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    color="error" 
                    onClick={(e) => handleDeleteClick(e, post)}
                    title="Delete Blog Post"
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default AdminBlogs; 