import SteriodFreeImg from "../assets/antibiotic-and-steriod-free-hens.png"
import FreeRangeHens from "../assets/free-range-hens.png"
import NoForceFeeding from "../assets/no-force-feeding-hens.png"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Unstable_Grid2"
import ComponentLoader from "../components/ComponentLoader"
import SearchIcon from '@mui/icons-material/Search';
import CatNutrisoft1 from '../assets/better-than-broiler.png'

import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Avatar,
  Typography,
  Rating,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import HomeBanner1 from "../assets/banner1.png"
import HomeBanner2 from "../assets/banner2.png"
import HomeBanner3 from "../assets/banner3.png"
import HomeBanner4 from "../assets/desk-banner1.png"
import HomeBanner5 from "../assets/desk-banner2.png"
import HomeBanner6 from "../assets/desk-banner3.png"
import SideMenuLogo from "../assets/cc-logo-home.png"
import MenuBg from "../assets/menuBg.png"
import backIcon from "../assets/back-icon.png"
import CatFreeRange from "../assets/cat-freerange.png"
import CatVillageBirds from "../assets/cat-villagebirds.png"
import GoogleIcon from "../assets/googleIcon.png"
import CountryMutton from "../assets/cat-mutton.png"
import { useState, useContext, useEffect } from "react"
import { CommonContext, CommonProvider } from "../contexts/CommonContext"
import { useLocation, useNavigate } from "react-router-dom"
import { color, motion } from "framer-motion"
import { AuthContext } from "../contexts/AuthContext"
import { Geolocation } from "@capacitor/geolocation"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Autoplay, Pagination } from "swiper"
import { Preferences } from "@capacitor/preferences"
import Drawer from "@mui/material/Drawer"
import FooterLogo from "../assets/lan-footer.png"

import * as React from "react"
import {
  generateAuthKey,
  getCustomizedProducts,
  getImgMap,
  getLanding,
  getMetaData,
  handleDeviceToken,
  logAction,
  getUserProfileData,
} from "../services/api"
import { getFirebaseError } from "../services/error-codes"
import { Capacitor } from "@capacitor/core"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import CatFreeRange1 from "../assets/land-cat-freerange.png"
import CatMarinates1 from "../assets/land-cat-marinates.png"
import CatEggs1 from "../assets/land-cat-eggs.png"
import CatPickles1 from "../assets/land-cat-pickle.png"
import { MarginOutlined, Person2Rounded } from "@mui/icons-material"
import { CapacitorUpdater } from "@capgo/capacitor-updater"
import { App as CapApp } from "@capacitor/app"
import AppBlocker from "../components/AppBlocker"

import OfferPopup from "../assets/offer-popup.png"
import "../App.css"
import { PushNotifications } from "@capacitor/push-notifications"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import BtbProduct from "../assets/btb-product.png"
import TccProduct from "../assets/tcc-product.png"
import BrownEggProduct from "../assets/brown-30pack-product.png"
import TenderLoved from '../assets/loved-tender.png'

const styles = {
  navbar: {
    height: "10vh",
    // borderBottom : '1px solid #b1b1b1',
    position: "fixed",
    top: "0",
    left: "0",
    width: "92vw",
    background: "#FFF0D9",
    zIndex: "2",
    // boxShadow: '0px 0px 5px 0px #7e7e7e',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainContent: {
    height: "auto",
    paddingTop: "8vh",
    background: "#FFF0D9",
  },
  mainContentDesk: {
    height: "100vh",
    paddingTop: "10vh",
  },
  whyccc: {
    background: "#a4243d",
    color: "#FFF0D9",
    display: "flex",
    padding: "4vw 2vw",
    textAlign: "center",
    margin: "20px",
  },
  contentImg: {
    minWidth: "200px",
    width: "20vw",
    marginBottom: "15px",
  },
  contentCont: {},
  productItem: {
    margin: "10px 15px 10px 10px",
    background: "white",
    borderRadius: "5px",
    width: "220px",
    height: "315px",
    boxShadow: "0px 0px 2px 1px #eaeaea",
  },

  productItemDesk: {
    margin: "10px",
    background: "white",
    borderRadius: "5px",
    width: "230px",
    height: "330px",
    // marginRight:'25px',
    boxShadow: "0px 0px 5px 2px #eaeaea",
  },
  mostLoved: {
    alignItems: "center",
    margin: "5px",
    padding: "5px",
  },
  productCatTitle: {
    padding: "5vw 6vw 5px",
    fontSize: "20px",
    fontWeight: "500",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    color: "#a4243d",
  },

  productCatTitleDesk: {
    padding: "35px 30px 5px 30px",
    fontSize: "20px",
    fontWeight: "500",
    color: "#a4243d",
  },

  productGridCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productImg: {
    height: "180px",
    width: "320px",
    borderRadius: "5px 5px 0 0",
  },
  productImgDesk: {
    height: "180px",
    width: "320px",
    borderRadius: "5px 5px 0 0",
  },

  productDescCont: {
    padding: "15px",
    height: "120px",
    textAlign: "left",
    alignItems: "center",
    // background:'#ffebeb',
    borderRadius: "0px 0px 5px 5px",
    display: "grid",
    position: "relative",
    borderTop: "1px solid #eaeaea",
    // width:'170px'
  },
  mainBtn: {
    backgroundColor: "a4243d !important",
    "&:hover": {
      background: "white",
    },
  },
  bannerImg: {
    width: "-webkit-fill-available",
    // margin:'20px',
    // borderRadius:'10px',
    height: "18vh",
  },
  bannerImgDesk: {
    width: "-webkit-fill-available",
    // margin:'25px',
    // borderRadius:'10px',
    height: "40vh",
  },
  incCont: {
    display: "flex",
    justifyContent: "right",
    padding: "5px",
  },

  userLogo: {
    width: "11vw",
    height: "11vw",
    maxWidth: "50px",
    maxHeight: "50px",
    border: "10px solid #eaeaea",
    padding: "2px",
    borderRadius: "50%",
  },
  homeLogo: {
    cursor: "pointer",
    height: "50px",
  },
  posterImg: {
    width: "160px",
    height: "160px",
    marginBottom: "10px",
    boxShadow: "0 0 2px 0px #b3b1b1",
    borderRadius: "2px",
  },
  posterImgDesk: {
    width: "160px",
    height: "160px",
    marginBottom: "10px",
    boxShadow: "0 0 5px 0px #b3b1b1",
    borderRadius: "2px",
  },
  posterCont: {
    textAlign: "center",
    borderRadius: "10px",
    // boxShadow: "0px 0px 10px 0px #eaeaea",
    margin: "10px 20px",
    fontSize: "0.9rem",
    fontWeight: "bold",
    fontFamily: "roboto",
  },

  posterContDesk: {
    margin: "20px",
    padding: "20px",
    textAlign: "center",
    width: "200px",
    height: "200px",
    color: "#a4243d",
    fontWeight: "bold",
    // background:'#ffebeb',
    // borderRadius:'10px',
    // boxShadow:'0px 0px 10px 0px #eaeaea',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  activeExtra: {
    background: "#F47F13",
    color: "white",
    borderRadius: "5px",
    padding: "7px 15px",
    cursor: "pointer",
    boxShadow: "1px 1px 5px 3px #eaeaea",
    display: "flex",
    alignItems: "baseline",
  },
  inactiveExtra: {
    padding: "8px 15px",
    cursor: "pointer",
    display: "flex",
    alignItems: "baseline",
    border: "1px solid #eaeaea",
    borderRadius: "5px",
  },
  disabled: {
    opacity: "0.5",
    pointerEvents: "none",
  },
  productCatCont: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    marginBottom: "10px",
    padding: "0 10px",
  },
  productCatContDesk: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    marginBottom: "10px",
    padding: "0 20px",
  },

  navItem: {
    cursor: "pointer",
    padding: "10px",
  },
  cartCont: {
    cursor: "pointer",
    padding: "10px 20px",
    borderRadius: "10px",
    border: "1px solid #a4243d",
    display: "flex",
    alignItems: "center",
    color: "#a4243d",
    marginLeft: "10px",
  },
  offerImgDesk: {
    height: "150px",
    width: "450px",
    marginRight: "20px",
    borderRadius: "3px",
  },
  offerImg: {
    height: "150px",
    width: "100%",
    // marginRight:'20px',
    borderRadius: "3px",
  },
  prodName: {
    textAlign: "left",
    marginBottom: "3px",
    fontWeight: "450",
    fontSize: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      color: "#a4243d",
    },
  },
  prodImgCont: {
    textAlign: "center",
    height: "180px",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  discountCont: {
    background: "#a4243d",
    color: "white",
    top: "10px",
    fontSize: "13px",
    boxShadow: "0 0 5px -1px white",
    padding: "5px 8px",
    width: "min-content",
    position: "absolute",
    width: "auto",
    right: "0",
    borderRadius: "3px 0 0 3px",
  },
  topCatCont: {
    display: "inline-grid",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 2fr))",
    width: "100vw",
    height: "auto",
  },
  topCatContDesk: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  mobMenuItem: {
    padding: "10px 20px",
  },
  popupImg: {
    width: "80vw",
    maxWidth: "400px",
  },
  catTitleCont: {
    position: "relative",
    overflow: "hidden",
  },
  overlayText1: {
    position: "relative",
    color: "#fff0d9",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    zIndex: 1,
    padding: "20px",

    lineHeight: "25px",
    bottom: 0,
    marginTop: "30px",
    background: "#a4243d",
    marginBottom: "20px",
  },

  overlayText2: {
    position: "relative",
    color: "#fff0d9",

    padding: "10px",

    margin: "2px",

    marginBottom: "10px",
  },

  catTitle: {
    fontSize: "20px",
    lineHeight: "35px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
  },
  catDummyCont: {
    position: "absolute",
    top: 0,
    height: "100%",
    left: "-100px",
    width: "30%",
    background: "linear-gradient(to right, white, transparent 50%)",
    webkitTextFillColor: "transparent",
    animation: "shine 2.5s infinite",
    overflow: "hidden",
  },
}

let updateData = null

function Home() {
  const navigate = useNavigate()
  const {
    updateCart,
    cartData,
    isDesktop,
    showAlert,
    showPopup,
    setBlocker,
    setUpdatePercent,
    clearCart,
    setPopup,
    hideAlert,
    showSnackbar,
    landingPopupShown,
    setLandingPopupShown,
    clearCouponData,
  } = useContext(CommonContext)
  const {
    isUserLoggedIn,
    getCustomerId,
    getUserId,
    getUserMobile,
    userProfileData,
  } = useContext(AuthContext)
  let x = useContext(AuthContext)
  const [anchor, setAnchor] = useState(false)
  const [sideNavAnchor, setSideNavAnchor] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showSearchBar, setShowSearchBar] = useState(false)

  const [skinType, setSkinType] = useState("withskin")
  const [boneType, setBoneType] = useState("withBones")
  const [flavourType, setFlavourType] = useState("normal")
  const [cutType, setCutType] = useState("medium")

  const [selectedItem, setSelectedItem] = useState(null)

  const [itemsData, setItemsData] = useState([])
  const [allItemsData, setAllItemsData] = useState([])
  const [filteredItemsData, setFilteredItemsData] = useState([])

  const [latLong, setLatLong] = useState(null)
  const [metaData, setMetaData] = useState(getMetaData()["home"])
  const [hasOfferItems, setHasOfferItems] = useState(false)
  const [timer, setTimer] = useState(30)
  const testimonials = [
    {
      title: "Fresh and Delicious!",
      description:
        "The country chicken was incredibly fresh, and the taste was unmatched.",
      customerName: "Rahul",
      location: "Gachibowli",
      avatar: "https://via.placeholder.com/50",
      rating: 5,
    },
    {
      title: "Tender Mutton",
      description:
        "The mutton was tender and cooked perfectly. Highly recommendThe mutton was tender and cooked perfectly.",
      customerName: "Sneha ",
      location: "Kondapur",
      avatar: "https://via.placeholder.com/50",
      rating: 4.5,
    },
    {
      title: "Farm Fresh Eggs!",
      description: "The eggs were fresh and organic, just like they promised.",
      customerName: "Arjun ",
      location: "Madhapur",
      avatar: "https://via.placeholder.com/50",
      rating: 5,
    },
    {
      title: "Amazing Quality!",
      description:
        "The country chicken was so tender and juicy. Perfect for a family meal.",
      customerName: "Meena",
      location: "Banjara Hills",
      avatar: "https://via.placeholder.com/50",
      rating: 4.8,
    },
    {
      title: "Excellent Service!",
      description:
        "The mutton was delivered on time and was super fresh. Great experience!",
      customerName: "Vikram ",
      location: "Jubilee Hills",
      avatar: "https://via.placeholder.com/50",
      rating: 5,
    },
  ]
  const [profileData, setProfileData] = useState({})
  const mostLoved = [
    {
      image: TenderLoved,
      title: "Tender Country Chicken",
      url: "/products/tender-country-chicken",
    },
    {
      image: BtbProduct,
      title: "Better than Broiler",
      url: "/products/better-than-broiler-chicken",
    },
    {
      image: BrownEggProduct,
      title: "Brown  Eggs",
      url: "/products/brown-eggs",
    },
  ]

  CapacitorUpdater.addListener("download", (event) => {
    console.log("***Download in progress***", JSON.stringify(event))
    setUpdatePercent(event.percent)
  })

  const getUserProfile = async () => {
    getUserProfileData(await getUserId())
      .then((response) => {
        setProfileData(response)
      })
      .catch((error) => {
        setLoading(false)
        showAlert(getFirebaseError(error))
      })
  }

  const requestForLocPermission = async () => {
    await Geolocation.requestPermissions("location")
      .then(async (resp) => {
        if (resp.location == "granted") {
          printCurrentPosition()
        } else {
          showAlert(
            "Location permission is disabled. Please enable location to order from nearest store"
          )
          const params = {
            packageVersion: process.env.REACT_APP_VERSION,
            platform: Capacitor.getPlatform(),
            userMobile: await getUserId(),
          }
          getLandingData(params)
        }
      })
      .catch(async (err) => {
        showAlert(
          "Location permission is disabled. Please enable location to order from nearest store"
        )
        const params = {
          packageVersion: process.env.REACT_APP_VERSION,
          platform: Capacitor.getPlatform(),
          userMobile: await getUserId(),
        }
        getLandingData(params)
      })
  }

  const printCurrentPosition = async () => {
    await Geolocation.getCurrentPosition()
      .then(async (resp) => {
        const params = {
          packageVersion: process.env.REACT_APP_VERSION,
          platform: Capacitor.getPlatform(),
          userMobile: await getUserId(),
          location: {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          },
        }
        getLandingData(params)
      })
      .catch((err) => {
        requestForLocPermission()
      })
  }

  const closePopup = async () => {
    if (await isUserLoggedIn()) {
      hideAlert()
      showSnackbar("Login successfull, continue to order")
    } else {
      hideAlert()
      navigate("/auth")
    }
  }

  async function getLandingData(params) {
    params.query = window.location.search
    getLanding(params)
      .then(async (resp) => {
        if (
          window.location.pathname == "/home" ||
          (!isDesktop && window.location.pathname == "/")
        ) {
          if (resp.showLandingPopup && !landingPopupShown) {
            showPopup(
              <>
                <Box onClick={() => closePopup()}>
                  <img src={OfferPopup} style={styles.popupImg} />
                </Box>
              </>
            )
            setLandingPopupShown(true)
          }
        }

        if (resp.action == "UPDATE" && Capacitor.getPlatform() != "web") {
          setBlocker(true)

          updateData = await CapacitorUpdater.download({
            version: resp.currVersion,
            url: resp.newBuildUrl,
          })

          await clearCart()
          await CapacitorUpdater.set(updateData)
          setBlocker(false)
        } else {
          if (Capacitor.getPlatform != "web") {
            const deviceTokenResp = await Preferences.get({
              key: "deviceToken",
            })
            if (!deviceTokenResp?.value) {
              getDeviceToken()
            }
          }

          setItemsData(resp.productsData)
          let allCatItems = []
          resp.productsData.forEach((item) => {
            allCatItems = allCatItems.concat(item.data)
          })
          if (resp.clearCart) {
            await clearCart()
            await clearCouponData()
          }
          setHasOfferItems(resp.hasOfferItems)
          setAllItemsData(allCatItems)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  async function addToCart(item) {
    if (getCustomizedProducts().includes(item.id)) {
      setSelectedItem(item)
      setAnchor(true)
    } else {
      updateCart(item, true)
    }
  }

  async function modifyCart(item, isIncrease) {
    updateCart(item, isIncrease)
  }

  async function goToProfile() {
    if (await isUserLoggedIn()) {
      navigate("/profile")
    } else {
      navigate("/auth")
    }
  }
  async function goToAddress() {
    if (await isUserLoggedIn()) {
      navigate("/myAddresses")
    } else {
      navigate("/auth")
    }
  }
  async function goToRecipe() {
    await navigate("/recipies")
  }
  async function goToAbout() {
    await navigate("/aboutUs")
  }
  async function goToContact() {
    await navigate("/contactus")
  }
  async function goToFaqs() {
    await navigate("/faqs")
  }
  async function goToStores() {
    await navigate("/stores")
  }
  async function goToPrivacy() {
    await navigate("/privacyPolicy")
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setAnchor(open)
  }

  const toggleSideNavDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setSideNavAnchor(open)
  }

  const downloadApp = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.countrychicken.customerapp",
      "_blank"
    )
  }

  const modifySkinType = (type) => {
    setSkinType(type)
    if (type == "skinless") {
      setFlavourType("normal")
    }
  }

  const modifyBoneType = (type) => {
    setBoneType(type)
  }

  const searchProductsEvent = (value) => {
    if (!value) {
      setFilteredItemsData([])
      return
    }

    const newItemsData = allItemsData.filter((item) =>
      item.name
        .split(" ")
        .join("")
        .toLowerCase()
        .match(value.toLowerCase().split(" ").join(""))
    )
    setFilteredItemsData(newItemsData)
  }

  const closeSearch = () => {
    setShowSearchBar(false)
    setFilteredItemsData([])
  }

  const addItemFromExtras = () => {
    let activeItem = selectedItem

    activeItem.extras = {
      skinType: skinType,
      flavourType: flavourType,
      cutType: cutType,
      boneType: boneType,
    }
    updateCart(activeItem, true)
    setSelectedItem(null)
    setSkinType("withskin")
    setFlavourType("normal")
    setCutType("medium")
    setAnchor(false)
  }

  async function getLandingForIos() {
    const params = {
      packageVersion: process.env.REACT_APP_VERSION,
      platform: Capacitor.getPlatform(),
      userMobile: await getUserMobile(),
      customerId: await getCustomerId(),
    }
    getLandingData(params)
  }

  const getDeviceToken = () => {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register()
      } else {
        // Show some error
      }
    })

    PushNotifications.addListener("registration", async (token) => {
      const resp = await handleDeviceToken({
        deviceId: token.value,
        mobileNo: await getUserMobile(),
      })
      const resp1 = await Preferences.set({
        key: "deviceToken",
        value: token.value,
      })
    })
  }

  const list = (anchor) => (
    <Box sx={{ padding: "4vw" }}>
      <Box
        sx={{
          fontSize: "15px",
          fontWeight: "600",
          mb: 2,
          color: "#a4243d",
          borderBottom: "1px solid #eaeaea",
        }}
      >
        Customize your order
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box>Chicken Type</Box>
        {selectedItem?.skinType ? (
          <>
            <Box
              sx={{
                display: "flex",
                padding: "15px 0 20px 0",
                borderBottom: "1px solid #eaeaea",
                mb: 3,
              }}
            >
              <Box
                sx={{ mr: 3 }}
                style={
                  skinType == "withskin"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifySkinType("withskin")}
              >
                With Skin
              </Box>
              <Box
                style={
                  skinType == "skinless"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifySkinType("skinless")}
              >
                Without Skin{" "}
                <Box sx={{ fontSize: "12px", marginLeft: "5px" }}>
                  (+ ₹100/-)
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
        {selectedItem?.boneType ? (
          <>
            <Box
              sx={{
                display: "flex",
                padding: "15px 0 20px 0",
                borderBottom: "1px solid #eaeaea",
                mb: 3,
              }}
            >
              <Box
                sx={{ mr: 3 }}
                style={
                  boneType == "withBones"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifyBoneType("withBones")}
              >
                With Bone
              </Box>
              <Box
                style={
                  boneType == "boneless"
                    ? styles.activeExtra
                    : styles.inactiveExtra
                }
                onClick={() => modifyBoneType("boneless")}
              >
                Boneless{" "}
                <Box sx={{ fontSize: "12px", marginLeft: "5px" }}>
                  (+ ₹349/-)
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
        {selectedItem?.flavourType ? (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              style={skinType == "withskin" ? null : styles.disabled}
            >
              <Box>Chicken Flavour</Box>
              <Box
                sx={{
                  display: "flex",
                  padding: "15px 0 20px 0",
                  borderBottom: "1px solid #eaeaea",
                  mb: 3,
                  alignItems: "center",
                }}
              >
                <Box
                  style={
                    flavourType == "normal"
                      ? styles.activeExtra
                      : styles.inactiveExtra
                  }
                  onClick={() => setFlavourType("normal")}
                >
                  Normal
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  style={
                    flavourType == "smoketurmeric"
                      ? styles.activeExtra
                      : styles.inactiveExtra
                  }
                  onClick={() => setFlavourType("smoketurmeric")}
                >
                  Smoked & Turmeric{" "}
                  <Box sx={{ fontSize: "12px", marginLeft: "5px" }}>
                    (+ ₹15/-)
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box>Chicken Cut</Box>
        <Box
          sx={{
            display: "flex",
            padding: "15px 0 20px 0",
            borderBottom: "1px solid #eaeaea",
            mb: 4,
          }}
        >
          <Box
            sx={{ mr: 3 }}
            style={
              cutType == "small" ? styles.activeExtra : styles.inactiveExtra
            }
            onClick={() => setCutType("small")}
          >
            Small
          </Box>
          <Box
            style={
              cutType == "medium" ? styles.activeExtra : styles.inactiveExtra
            }
            onClick={() => setCutType("medium")}
          >
            Medium
          </Box>
          <Box
            style={
              cutType == "biryani" ? styles.activeExtra : styles.inactiveExtra
            }
            onClick={() => setCutType("biryani")}
          >
            Biryani Cut
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => addItemFromExtras()}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  )

  useEffect(() => {
    logAction("PAGE_VIEW", "home")
    getUserProfile()
    // if (Capacitor.getPlatform() == 'ios') {
    //Immediate fix for ver 3 as there is no location access
    //   getLandingForIos()
    // } else {
    //   printCurrentPosition()
    // }

    var id = setInterval(function () {
      setTimer((prevState) => prevState - 1)
    }, 1000)
    setTimeout(function () {
      clearInterval(id)
    }, 30000)

    logAction("APP_OPEN", Capacitor.getPlatform())
    getLandingForIos()
    generateAuthKey()
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        x: -window.innerWidth,
        transition: { duration: isDesktop ? 0 : 0.1 },
      }}
    >
      <Box>
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.description} />
          <meta name="keywords" content={metaData.keywords} />
        </Helmet>
        {isDesktop ? <Header /> : null}

        {isDesktop ? null : (
          <Box
            style={styles.navbar}
            sx={{
              padding:
                Capacitor.getPlatform() == "ios" ? "5vh 4vw 0 4vw" : "0 4vw",
            }}
          >
            <Box onClick={() => setSideNavAnchor(true)}>
              <MenuIcon sx={{ color: "#000" }} />
            </Box>
            <Box onClick={() => navigate("/")}>
              <img src={FooterLogo} style={styles.homeLogo} />
            </Box>
            <Box
              sx={{
                display: "flex",
                background: "lightgrey",
                padding: "5px",
              borderRadius: "25px",
              }}
              onClick={() => goToProfile()}
            >
              <Person2Rounded style={{ color: "#a4243d" }} />
            </Box>
          </Box>
        )}

        {loading ? (
          <Box sx={{ padding: "4vw" }}>
            <ComponentLoader />
          </Box>
        ) : (
          <div style={isDesktop ? styles.mainContentDesk : styles.mainContent}>
            {isDesktop ? null : (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 10px",
                    background: "transparent",
                    height: "10vh",
                    justifyContent: "flex-end",
                  }}
                >
                  <OutlinedInput
                    placeholder="Search Our Farm"
                    variant="outlined"
                    type="text"
                    size="small"
                    autoComplete="off"
                    name="searchProducts"
                    fullWidth
                    sx={{
                      border: "1px solid #a4243d",
                      color: "#a4243d",
                      height: "2em",
                      margin: "5px",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon
                            sx={{
                              color: "#FFF0D9",
                              background: "#a4243d",
                              borderRadius: "5px",
                              padding: "2px",
                              fontSize: "1.2rem",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(event) => {
                      searchProductsEvent(event.target.value)
                    }}
                  />
                </Box>

                {filteredItemsData.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      top: Capacitor.getPlatform() == "ios" ? "20vh" : "17vh",
                      left: 0,
                      background: "white",
                      width: "100vw",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      zIndex: "333",
                    }}
                  >
                    {filteredItemsData.map((item) => {
                      return (
                        <Box
                          sx={{
                            padding: "10px",
                            borderBottom: "2px solid white",
                            cursor: "pointer",
                            background: "#f3f3f3",
                          }}
                          onClick={() =>
                            navigate(`/products/${item.urlId}`, { state: item })
                          }
                        >
                          {item.name} ({item.qty})
                        </Box>
                      )
                    })}
                  </Box>
                ) : null}
              </Box>
            )}

            <Box sx={{ padding: isDesktop ? null : "0 4vw" }}>
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                autoplay={{ delay: 3500, disableOnInteraction: false }}
                modules={[Pagination, Autoplay]}
                className={isDesktop ? null : "mySwiper"}
              >
                <SwiperSlide>
                  <img
                    src={isDesktop ? HomeBanner5 : HomeBanner2}
                    style={isDesktop ? styles.bannerImgDesk : styles.bannerImg}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={isDesktop ? HomeBanner4 : HomeBanner1}
                    style={isDesktop ? styles.bannerImgDesk : styles.bannerImg}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={isDesktop ? HomeBanner6 : HomeBanner3}
                    style={isDesktop ? styles.bannerImgDesk : styles.bannerImg}
                  />
                </SwiperSlide>
              </Swiper>
            </Box>

            <Box style={styles.overlayText1}>
              <Box
                sx={{
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginBottom: "20px",
                  textAlign: "center",
                  textDecoration: "underline",
                  fontFamily: "Foregen",
                }}
              >
                Most Loved
              </Box>

              <Swiper
                spaceBetween={isDesktop ? 15 : 0}
              >
                {mostLoved.map((mostLoved, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ maxWidth: isDesktop ? "fit-content" : "200px", width:'160px' }}
                  >
                    <Box
                      sx={{
                        borderRadius: "7px",
                        display: "flex",
                        flexDirection: "column",
                        background: "#A4243D",
                      }}
                    >
                      <Box
                        onClick={() => navigate(mostLoved.url)}
                        sx={{
                          border: "2px solid #FFF0D9",
                          height: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                          cursor: "pointer",
                          width:'170px'
                        }}
                      >
                        <img
                          src={mostLoved.image}
                          alt={mostLoved.title}
                          style={{
                            maxHeight: "100%",
                            height:'150px',
                            width:'170px'
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          fontWeight: "500",
                          fontFamily: "Roboto",
                          fontSize: "13px",
                          marginTop: "8px", // Add spacing from the bordered box
                          textAlign: "center", // Center align the title text
                        }}
                      >
                        {mostLoved.title}
                      </Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>

              {/*
        <Swiper  spaceBetween={15} slidesPerView={2}  
            >
      {mostLoved.map((item, index) => (
        <SwiperSlide key={index} style={styles.mostLoved} >
          <Box >
            <img src={item.image} alt={item.title} style={{ width: '85%' }} />
         
          </Box>
          <h6>{item.title}</h6>
        </SwiperSlide>
      ))}
    </Swiper> */}
            </Box>

            <Box
              sx={{
                padding: isDesktop ? "3vw 3vw 1vw 2vw" : "20px 4vw 10px 4vw",
                fontSize: "24px",
                fontWeight: "400",
                display: "grid",
                color: "#000",
                fontFamily: "Foregen",
                textAlign: "center",
              }}
            >
              Categories
            </Box>
            <Box sx={isDesktop ? styles.topCatContDesk : styles.topCatCont}>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
                onClick={() =>
                  navigate("/categories/better-than-broiler-chicken")
                }
              >
                <img
                  src={CatNutrisoft1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                BTB Chicken 
              </Box>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
                onClick={() => navigate("/categories/free-range-birds")}
              >
                <img
                  src={CatFreeRange1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Country Chicken (Natukodi)
              </Box>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
                onClick={() => navigate("/categories/mutton")}
              >
                <img
                  src={CountryMutton}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Country Mutton
              </Box>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
                onClick={() => navigate("/categories/eggs")}
              >
                <img
                  src={CatEggs1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Eggs
              </Box>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
                onClick={() => navigate("/categories/pickles")}
              >
                <img
                  src={CatPickles1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Pickles
              </Box>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
                onClick={() => navigate("/categories/Amma-Chethi-Marinades")}
              >
                <img
                  src={CatMarinates1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Marinates
              </Box>
              {/* <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
              >
                <img
                  src={CatMarinates1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Exotic Range
              </Box>
              <Box
                style={isDesktop ? styles.posterContDesk : styles.posterCont}
              >
                <img
                  src={CatMarinates1}
                  style={isDesktop ? styles.posterImgDesk : styles.posterImg}
                />
                Naatu Masalas
              </Box> */}
            </Box>

            <Box></Box>

            {/* <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid xs={12} lg={6} sx={{ textAlign: "right" }}>
                <img
                  src={OfferBanner1}
                  style={isDesktop ? styles.offerImgDesk : styles.offerImg}
                />
              </Grid>
              {Capacitor.getPlatform() == "web" ? (
                <Grid xs={12} lg={6}>
                  <img
                    src={OfferBanner2}
                    style={isDesktop ? styles.offerImgDesk : styles.offerImg}
                    onClick={() => downloadApp()}
                  />
                </Grid>
              ) : null}
            </Grid> */}

            {/*
{itemsData.length &&
  itemsData.map((category, index) => {
    return (
      <Box key={index}>
        <Box
          style={
            isDesktop
              ? styles.productCatTitleDesk
              : styles.productCatTitle
          }
        >
          <Box>{category.title}</Box>
          {isDesktop ? null : (
            <Box
              sx={{
                fontSize: "15px",
                display: "flex",
                alignItems: "center",
                color: "black",
              }}
              onClick={() => navigate(`/categories/${category.id}`)}
            >
              View All
              <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
            </Box>
          )}
        </Box>
        <Box
          style={
            isDesktop
              ? styles.productCatContDesk
              : styles.productCatCont
          }
          sx={{
            background:
              category.type == "OFFER_PRODUCT" ? "wheat" : null,
          }}
        >
          {category.data.map((item) => {
            return (
              <Box
                style={
                  isDesktop
                    ? styles.productItemDesk
                    : styles.productItem
                }
                key={item.id}
              >
                <Box
                  sx={styles.prodImgCont}
                  onClick={() =>
                    navigate(`/products/${item.urlId}`, {
                      state: item,
                    })
                  }
                >
                              {/* <Box sx={styles.discountCont}>
 {Math.trunc(((item.mrp - item.price) / item.mrp) * 100)}% Off
 </Box>  *}
                  <img
                    src={getImgMap()[item.imgUrl]}
                    style={
                      isDesktop
                        ? styles.productImgDesk
                        : styles.productImg
                    }
                  />
                </Box>
                <div style={styles.productDescCont}>
                  <Box
                    sx={styles.prodName}
                    onClick={() =>
                      navigate(`/products/${item.urlId}`, {
                        state: item,
                      })
                    }
                  >
                    {item.name}
                    <Box sx={{ fontSize: "15px", opacity: "0.2" }}>
                      {item.qty}
                    </Box>
                  </Box>
                  {item.style ? (
                    <Box
                      sx={{
                        textAlign: "left",
                        marginBottom: "5px",
                        fontSize: "12px",
                      }}
                    >
                      ({item.style})
                    </Box>
                  ) : null}

                              {/* <Box sx={{textAlign:'left', marginBottom:'5px', fontWeight:'450', fontSize:'15px'}}>
 {item.qty}
 </Box> *}

                  {category.type == "OFFER_PRODUCT" ? (
                    <>
                      <Box>
                        <Box
                          sx={{
                            fontSize: "12px",
                            color: "#f47f13",
                            borderLeft: "1px solid #eaeaea",
                          }}
                        >
                          <Box sx={{ marginBottom: "15px" }}>
                            Offer only for you
                          </Box>
                        </Box>
                        {item.stockQty == 0 ? (
                          <Button
                            variant="outlined"
                            style={styles.mainBtn}
                            size="small"
                            fullWidth
                            disabled
                            sx={{ opacity: "0.6" }}
                          >
                            Out of stock
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            style={styles.mainBtn}
                            size="small"
                            fullWidth
                            onClick={() =>
                              navigate(`/products/${item.urlId}`)
                            }
                          >
                            Claim Now
                          </Button>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      {item.livePrice ? (
                        <Box
                          sx={{ fontSize: "10px", opacity: "0.5" }}
                        >
                          Starting From
                        </Box>
                      ) : null}
                      <Box
                        sx={{
                          textAlign: "left",
                          marginBottom: "5px",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "end",
                        }}
                      >
                        ₹ {item.livePrice || item.price}
                        {item.enableBogo ? null : (
                          <Box
                            sx={{
                              fontSize: "13px",
                              marginLeft: "5px",
                              opacity: "0.2",
                            }}
                                      >
                                        {/* {
                                item.mrp ? <s>₹ {item.mrp}</s> : null
                              } *}
                                      </Box>
                        )}
                                    {/* <Box sx={{fontSize:'12px', marginLeft:'5px', color:'#f47f13', borderLeft:'1px solid #eaeaea', paddingLeft:'5px'}}>
                            {
                              item?.enableBogo ?
                                <Box sx={{marginBottom:'5px'}}>Buy One Get One FREE</Box> :
                                <>{item.mrp ? <>{Math.trunc(((item.mrp - (item.livePrice || item.price)) / item.mrp) * 100)} % Off</>  : null}</>
                            }                    
                            </Box> *}
                      </Box>
                      {cartData &&
                      cartData[item.id] &&
                      cartData[item.id].count ? (
                        <Box style={styles.incCont}>
                          <Box
                            sx={{
                              border: "1px solid #dddddd",
                              display: "flex",
                              borderRadius: "5px",
                              background: "white",
                              border: "1px solid #c3c3c3",
                            }}
                          >
                            <Box
                              onClick={() =>
                                modifyCart(item, false)
                              }
                              sx={{
                                padding: "5px 10px 5px 10px",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </Box>
                            <Box
                              sx={{
                                padding: "5px 10px",
                                borderRight: "1px solid #bababa",
                                borderLeft: "1px solid #bababa",
                                fontSize: "15px",
                                background: "#a4243d !important",
                                color: "white",
                              }}
                            >
                              {cartData[item.id].count}
                            </Box>
                            <Box
                              onClick={() => modifyCart(item, true)}
                              sx={{
                                padding: "5px 10px 5px 10px",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ textAlign: "right" }}>
                          {item.stockQty == 0 ? (
                            <Button
                              variant="outlined"
                              style={styles.mainBtn}
                              size="small"
                              disabled
                              sx={{ opacity: "0.6" }}
                            >
                              Out of stock
                            </Button>
                          ) : (
                            <>
                              {item?.onlyAtStores ? (
                                <Button
                                  variant="outlined"
                                  style={styles.mainBtn}
                                  size="small"
                                  fullWidth
                                  disabled
                                  sx={{ opacity: "0.6" }}
                                >
                                  Available at stores
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={styles.mainBtn}
                                  size="small"
                                  fullWidth
                                  onClick={() =>
                                    navigate(
                                      `/products/${item.urlId}`
                                    )
                                  }
                                >
                                  Shop Now
                                </Button>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </div>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  })}
*/}

            <Box style={styles.overlayText2}>
              <Box
                sx={{
                  fontSize: "24px",
                  fontWeight: "400",
                  marginBottom: "14px",
                  mt:4,
                  textAlign: "center",
                  textDecoration: "underline",
                  fontFamily: "Foregen",
                  color: "#000000",
                }}
              >
                WHAT OUR NAATU FANS SAYS
              </Box>
              <Swiper
                spaceBetween={10}
                style={{
                  position: "relative",
                  listStyle: "none",
                  padding: 0,
                  zIndex: 1,
                  display: "block",
                  width: "100%",
                  overflow: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ maxWidth: isDesktop ? "25%" : "45%" }}
                  >
                    <Box
                      sx={{
                        border: "2px solid #A4243D",
                        borderRadius: "7px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "150px", // Fixed height for the card
                        background: "#A4243D",
                      }}
                    >
                      {/* Title and Scrollable Description */}
                      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "400",
                            lineHeight: "18px",
                            margin: "10px",
                            fontSize: "17px",
                            fontFamily: "Foregen",
                          }}
                        >
                          {testimonial.title}
                        </Typography>

                        <Box
                          sx={{
                            overflowY: "auto", // Enable vertical scrolling for long descriptions
                            maxHeight: "100px", // Set maximum height for the description area
                            padding: "10px",
                            scrollbarWidth: "none",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "10px",
                              fontWeight: "400",
                              lineHeight: "12px",
                              fontFamily: "Roboto",
                            }}
                          >
                            {testimonial.description}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Footer */}
                      <Box
                        sx={{
                          marginTop: "auto",
                          background: "#FFF0D9",
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Avatar and Info */}
                        <Box sx={{ display: "flex", alignContent: "flex-end" }}>
                          <Avatar
                            src={testimonial.avatar}
                            alt={testimonial.customerName}
                            sx={{
                              width: 25,
                              height: 25,
                              marginLeft: "5px",
                              border: "0.5px solid #fff0d9",
                            }}
                          />
                          <Box
                            sx={{
                              marginLeft: "5px",
                              alignContent: "flex-end",
                              lineHeight: "2px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "400",
                                fontSize: "10px",
                                color: "#a4243d",
                                fontFamily: "Roboto",
                              }}
                            >
                              {testimonial.customerName}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#a4243d",
                                fontWeight: "400",
                                fontSize: "7px",
                              }}
                            >
                              {testimonial.location}
                            </Typography>
                          </Box>
                        </Box>

                        {/* Google Rating */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "24px",
                            }}
                          >
                            <img
                              src={GoogleIcon}
                              alt="Google"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              fontSize: "7px",
                              color: "#000",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "7px",
                                color: "#000",
                              }}
                            >
                              Google Rating
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "3px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "7px",
                                  color: "#000",
                                  fontWeight: "bold",
                                }}
                              >
                                {testimonial.rating}
                              </Typography>
                              <Rating
                                value={testimonial.rating}
                                precision={0.5}
                                readOnly
                                sx={{
                                  fontSize: "8px",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>

            <Box style={styles.whyccc}>
              <Grid container spacing={2}>
                <Grid xs={12} sm={4} md={4} lg={4}>
                  <div style={styles.contentCont}>
                    <div>
                      <img src={SteriodFreeImg} style={styles.contentImg} />
                    </div>
                    <div>
                      With an average bunch of 200+ country chickens and an
                      ample amount of farm time to roam free and munch on
                      hydroponics, our chickens are anything but cooped up.
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={4} md={4} lg={4}>
                  <div style={styles.contentCont}>
                    <div>
                      <img src={FreeRangeHens} style={styles.contentImg} />
                    </div>
                    <div>
                      Just like we don’t force-feed our children, we don’t
                      force-feed our chickens with any syringes since it might
                      lead to physical asphyxiation.
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={4} md={4} lg={4}>
                  <div style={styles.contentCont}>
                    <div>
                      <img src={NoForceFeeding} style={styles.contentImg} />
                    </div>
                    <div>
                      We believe country chickens are healthiest and happiest
                      when they’re allowed to live without being pumped full of
                      antibiotics, hormones & steroids.
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {Capacitor.getPlatform() == "web" ? <Footer /> : null}
          </div>
        )}
      </Box>

      <React.Fragment key={"bottom"}>
        <Drawer
          anchor={isDesktop ? "right" : "bottom"}
          open={anchor}
          onClose={toggleDrawer(false)}
        >
          {list("bottom")}
        </Drawer>
      </React.Fragment>

      <React.Fragment key={"left"}>
        <Drawer
          anchor={"left"}
          open={sideNavAnchor}
          onClose={toggleSideNavDrawer(false)}
        >
          <Box
            sx={{
              width: "60vw",
              padding:
                Capacitor.getPlatform() === "ios" ? "5vh 4vw 0 4vw" : "4vw",
              backgroundImage: `url(${MenuBg})`, // Corrected the property name
              backgroundSize: "cover", // Ensures the image covers the container
              backgroundPosition: "center", // Centers the image
              height: "100%",
              color: "#FFF0D9",
              fontFamily: "Roboto",
              gap: "10px",
            }}
          >
            <Grid
              xs={4}
              sm={4}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                marginLeft: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#FFF0D9",
                  fontSize: "22px",
                  marginTop: "50px",
                  cursor: "pointer",
                  marginLeft: "20px",
                  gap: "10px",
                }}
                onClick={() => goToProfile()}
              >
                <AccountCircleIcon />
                <Box
                  sx={{
                    fontWeight: "500",
                    fontSize: "22px",
                    // marginLeftt:'30px',
                    color: "#FFF0D9",
                    fontFamily: "Roboto",
                  }}
                >
                  {" "}
                  {profileData?.f_name}
                </Box>
              </Box>
              {/* Naatu COins */}
              {/* <Box
                sx={{
                  display: "flex",
                  width:'90px',
                  background: "#FFF0D9",
                  borderRadius: "50px",
                  padding: "5px 5px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  fontWeight:'400',
                  margin:'8px'
                }}
              >
               
                <Box
                  component="img"
                  src={natuIcon}
                  alt="points"
                  sx={{
                    width: "12px",
                    height: "12px",
                   
               
                  }}
                />

             
                <span
                  style={{
                    fontSize: "8px",
                    fontFamily: "Roboto",
                    color: "#a4243d",
                    fontWeight:'400'

                  }}
                >
                 199 Naatu Coins
                </span>
              </Box> */}
            </Grid>

            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() => goToProfile()}
            >
              {" "}
              Orders
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw",
                cursor: "pointer",
              }}
              onClick={() => goToAddress()}
            >
              {" "}
              Address
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw",
                cursor: "pointer",
              }}
              onClick={() => goToAbout()}
            >
              {" "}
              Our Story
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw 5px",
                cursor: "pointer",
                //  marginBottom:'10px'
              }}
              onClick={() => goToRecipe()}
            >
              {" "}
              Recipes
            </Box>

            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "8px 5vw",
                cursor: "pointer",

                marginTop: "10px",
              }}
              onClick={() => goToProfile()}
            >
              <Box
                sx={{
                  borderTop: "1px solid #FFF0D9",
                  width: "90%",
                  marginBottom: "10px",
                }}
              ></Box>{" "}
              Download Our App
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw",
                cursor: "pointer",
              }}
              onClick={() => goToContact()}
            >
              {" "}
              Contact Us
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw",
                cursor: "pointer",
              }}
              onClick={() => goToFaqs()}
            >
              {" "}
              FAQ's
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw",
                cursor: "pointer",
              }}
              onClick={() => goToStores()}
            >
              {" "}
              Our Stores
            </Box>
            <Box
              sx={{
                fcolor: "#FFF0D9",
                fontSize: "18px",
                padding: "5px 5vw ",
                cursor: "pointer",
              }}
              onClick={() => goToPrivacy()}
            >
              <Box
                sx={{
                  borderTop: "1px solid #FFF0D9",
                  width: "90%",
                  marginBottom: "10px",
                }}
              ></Box>{" "}
              Privacy Policy
            </Box>

            <img
              src={backIcon}
              style={{ position: "absolute", top: 30, right: 30 }}
              onClick={() => setSideNavAnchor(false)}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bottom: "100px",
                flexWrap: "wrap",
                padding: "10px",
                position: "absolute",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <img
                src={SideMenuLogo}
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  top: "40px",
                }}
              />
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  position: "relative",
                  top: "20px",

                  color: "#FFF0D9",
                  fontFamily: "Foregen",
                  textAlign: "center",
                }}
              >
                Raised By Nature
              </Box>
              {/* <img src={SideMenuName} style={{position:'relative',width:'100%',height:'100%',}} /> */}
            </Box>

            <Box
              sx={{
                fontWeight: "400",
                fcolor: "#FFF0D9",
                fontSize: "17px",
                cursor: "pointer",
                borderTop: "1px solid #FFF0D9",
                position: "absolute",
                bottom: 20,
                width: "80%",
                padding: "10px",
              }}
            >
              v12.0.0
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </motion.div>
  )
}

export default Home
