import React, { useContext, useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Typography, Paper, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem, InputLabel } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { CommonContext } from '../contexts/CommonContext';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getUserData, getAllUserAddress, getUserDeliveryData, getStockAvailability, getBranchInfo, getNearestStoreDetails, logAction, getLanding, getCategoryData, createNewOrder, getDeliveryCharge, createNewUser, addNewAddress } from '../services/api';
import { getFirebaseError } from '../services/error-codes';
import NavHeader from '../components/NavHeader';
import ComponentLoader from '../components/ComponentLoader';
import ItemsSummary from '../components/ItemsSummary';
import { motion } from "framer-motion";
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { Autocomplete as MUIAutocomplete } from '@mui/material';

const styles = {
  mainCont: {
    maxWidth: '500px',
    marginTop: '10vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px'
  },
  mainContDesk: {
    marginTop: '10vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1vw 20vw 5vw 20vw'
  },
  sectionTitle: {
    fontSize: "20px",
    marginBottom: "15px",
    fontFamily: "Foregen",
    color: "#404040",
    textDecoration: "underline",
    textDecorationColor: "#a4243d",
  },
  formField: {
    marginBottom: '15px'
  },
  shadowBox: {
    background: 'white',
    boxShadow: '0px 0px 10px 0px #eaeaea',
    padding: '15px',
    marginBottom: '20px',
    borderRadius: '5px'
  }
};

// Predefined credentials
const USERNAME = "admin-ccc";
const PASSWORD = "8179198765";

function AdminOrder() {
  // States
  const [customerMobile, setCustomerMobile] = useState('');
  const [customerExists, setCustomerExists] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showAddressList, setShowAddressList] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
  const [delSlot, setDelSlot] = useState("");
  const [delType, setDelType] = useState("delivery");
  const [delDate, setDelDate] = useState("Today");
  const [allDeliverySlots, setAllDeliverySlots] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [storeDetails, setStoreDetails] = useState(null);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productQuantity, setProductQuantity] = useState(1);
  const [selectedProductsList, setSelectedProductsList] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [map, setMap] = useState(null);
  const [searchResult, setSearchResult] = useState("Result: none");
  const [latLong, setLatLong] = useState({ lat: 28.6139, lng: 77.2090 }); // Default to Delhi
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check session storage for authentication flag
    return sessionStorage.getItem('isAuthenticated') === 'true';
  });
  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [authError, setAuthError] = useState("");
  
  const containerStyle = {
    width: '100%',
    height: '300px',
    marginBottom: '15px'
  };
  
  const placesLibrary = ["places"];
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: placesLibrary
  });
  
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(latLong);
    map.fitBounds(bounds);
    setMap(map);
  }, [latLong]);
  
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  
  const mapPositionChanged = (e) => {
    setLatLong({
      lat: e.lat(),
      lng: e.lng()
    });
  };
  
  function onAutoCompleteLoad(autocomplete) {
    setSearchResult(autocomplete);
  }
  
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      window['currentLocation'] = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      setLatLong(window['currentLocation']);
    } else {
      showAlert("Please enter address for map", "error");
    }
  }
  
  const printCurrentPosition = async() => {
    showLoader();
    try {
      const position = await Geolocation.getCurrentPosition();
      setLatLong({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      window['currentLocation'] = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      hideLoader();
    } catch (err) {
      requestForLocPermission();
      hideLoader();
    }
  };
  
  const requestForLocPermission = async() => {
    try {
      const permissions = await Geolocation.requestPermissions('location');
      if (permissions.location === 'granted') {
        printCurrentPosition();
      } else {
        showAlert("Location permission is disabled. Please enable location to continue");
      }
    } catch (err) {
      showAlert("Location permission is disabled. Please enable location to continue");
    }
  };

  // Context
  const { showLoader, hideLoader, showAlert, cartData, updateCart, isDesktop, showSnackbar } = useContext(CommonContext);
  const { isUserLoggedIn, getUserId } = useContext(AuthContext);
  
  // Navigation
  const navigate = useNavigate();

  // Form handling
  const { register: registerCustomer, handleSubmit: submitCustomer, formState: { errors: errorsCustomer } } = useForm();
  const { register: registerSignup, handleSubmit: submitSignup, formState: { errors: errorsSignup } } = useForm();
  const { register: registerAddress, handleSubmit: submitAddress, formState: { errors: errorsAddress } } = useForm();

  // Fetch all products on component mount
  useEffect(() => {
    fetchAllProducts();
  }, []);

  // Fetch all products
  const fetchAllProducts = async () => {
    setLoadingProducts(true);
    try {
      const userData = {
        "packageVersion": "21.0.0",
        "platform": "web",
        "userMobile": "8179198780",
        "customerId": "2",
        "query": ""
      }
      const landingData = await getLanding(userData);
      if (landingData && landingData.productsData) {
        // Extract all products from all categories
        let products = [];
        landingData.productsData.forEach(category => {
          if (category.data && category.data.length > 0) {
            products = [...products, ...category.data];
          }
        });
        setAllProducts(products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoadingProducts(false);
    }
  };

  // Handle product selection
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setProductQuantity(1);
  };

  // Add product to list
  const addProductToList = () => {
    if (!selectedProduct) {
      showAlert("Please select a product");
      return;
    }

    const productWithQuantity = {
      ...selectedProduct,
      quantity: productQuantity,
      id: selectedProduct.id // Add a unique ID for this selection
    };

    setSelectedProductsList([...selectedProductsList, productWithQuantity]);
    showSnackbar(`Added ${productQuantity} ${selectedProduct.name} to order`, "success");
    setSelectedProduct(null);
    setProductQuantity(1);
  };

  // Remove product from list
  const removeProductFromList = (productId) => {
    const updatedList = selectedProductsList.filter(product => product.id !== productId);
    setSelectedProductsList(updatedList);
    showSnackbar("Product removed from order", "success");
  };

  // Calculate total price
  const calculateTotalPrice = () => {
    return selectedProductsList.reduce((total, product) => {
      return total + (product.price * product.quantity) + (delType === 'delivery' ? 39 : 0);
    }, 0);
  };

  // Check if customer exists
  const checkCustomer = async (data) => {
    setLoading(true);
    setCustomerMobile(data.mobileNo);
    
    try {
      const userData = await getUserData(data.mobileNo, false);
      if (userData) {
        setCustomerExists(true);
        setCustomerData(userData);
        setShowProductList(true);
        fetchUserAddresses(data.mobileNo);
      } else {
        setCustomerExists(false);
        setShowProductList(false);
      }
    } catch (error) {
      setCustomerExists(false);
      setShowProductList(false);
    } finally {
      setLoading(false);
    }
  };

  // Register new customer
  const registerNewCustomer = async (data) => {
    showLoader();
    data.mobileNo = customerMobile;
    
    try {

      const userData = {
        userId      : data.mobileNo,
        timeStamp   : Date.now(),
        firstName : data.firstName,
        mobileNo : data.mobileNo
      }

      createNewUser(userData).then((response) => {
        showSnackbar("New customer created successfully")
        setCustomerData({
          f_name: data.firstName,
          mobileNo: customerMobile
        });
        setCustomerExists(true);
        setShowProductList(true);
        hideLoader();
      }).catch((error) => {
        hideLoader()
        showAlert(getFirebaseError(error.code))
      })

  
    } catch (error) {
      hideLoader();
      showAlert(getFirebaseError(error));
    }
  };

  // Fetch user addresses
  const fetchUserAddresses = async (mobileNo) => {
    showLoader();
    try {
      const addressData = await getUserDeliveryData(mobileNo);
        console.log("====",addressData.addresses);
      if (addressData.addresses && addressData.addresses.length > 0) {
        setAddresses(addressData.addresses);
        setShowAddressList(true);
        setShowAddressForm(false);
      } else {
        setShowAddressForm(true);
        setShowAddressList(false);
      }
    } catch (error) {
      setShowAddressForm(true);
      setShowAddressList(false);
    } finally {
      hideLoader();
    }
  };

  // Scroll to bottom of the page
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  // Proceed to order summary
  const proceedToOrderSummary = () => {
    if (!delSlot) {
      showAlert("Please select a delivery slot");
      return;
    }
    setShowOrderSummary(true);
    scrollToBottom();
  };

  // Add new address
  const addNewCustomerAddress = async (data) => {
    showLoader();
    try {
      // Here you would call your API to add a new address
      // For now, we'll simulate this
      const newAddress = {
        userName: `${customerData.f_name} ${customerData.l_name}`,
        houseDetails: data.addressLine1,
        streetDetails: data.addressLine2 || '',
        landmark: data.landmark || '',
        pincode: data.pincode,
        userId: customerMobile,
        timeStamp: Date.now(),
        addressId: Date.now().toString(),
        latLong: latLong,
        storeDetails: null
      };
      
      console.log("=====",newAddress)
      // Get nearest store details for delivery options
      addNewAddress(newAddress, false)
      .then((response) => {
        showSnackbar("New address added successfully")
      })
      .catch((error) => {
        hideLoader()
        showAlert(getFirebaseError(error))
      })

      const storeData = await getNearestStoreDetails(newAddress)
      console.log("=====",storeData)
      if (!storeData.branchId || !storeData.locCode) {
        showAlert(
          <>
            <b>
              We regret to inform you that delivery is not available for the
              selected address.
            </b>
            <br />
            <br /> Please note that we deliver within a maximum radius of 8km
            and the selected address lies beyond our delivery range.
          </>
        )
        hideLoader()
        return
      }
      
      if (storeData) {
        newAddress.storeDetails = storeData;
        setStoreDetails(storeData);
        fetchDeliverySlots(delDate, storeData.branchId);
      }
      
      setAddresses([...addresses, newAddress]);
      setSelectedAddress(newAddress);
      setShowAddressForm(false);
      setShowAddressList(true);
      setShowDeliveryOptions(true);
      
      hideLoader();
    } catch (error) {
      hideLoader();
      showAlert("Failed to add address. Please try again.");
    }
  };

  // Select address
  const selectAddress = (address) => {
    setSelectedAddress(address);
    setShowDeliveryOptions(true);
    
    // If the address already has store details, use them
    if (address.storeDetails) {
      setStoreDetails(address.storeDetails);
      fetchDeliverySlots(delDate, address.storeDetails.branchId);
    } else {
      // Get nearest store details for delivery options
      getNearestStoreDetails({
        pincode: address.pincode
      }).then(storeData => {
        if (storeData) {
          // Update the address with store details
          address.storeDetails = storeData;
          setStoreDetails(storeData);
          fetchDeliverySlots(delDate, storeData.branchId);
        }
      });
    }
  };

  // Fetch delivery slots
  const fetchDeliverySlots = async (deliveryDate, branchId) => {
    showLoader();
    try {
      const branchInfo = await getBranchInfo({
        branchId: branchId,
        deliveryDate: deliveryDate
      });
      if (branchInfo && branchInfo.slots) {
        setAllDeliverySlots(branchInfo.slots);
        setFilteredSlots(branchInfo.slots);
      }
    } catch (error) {
      console.error("Error fetching delivery slots:", error);
    } finally {
      hideLoader();
    }
  };

  // Handle delivery date change
  const onDelDateChange = (event) => {
    const newDate = event.target.value;
    setDelSlot(null);
    setDelDate(newDate);
    if (storeDetails) {
      fetchDeliverySlots(newDate, storeDetails.branchId);
    }
  };

  // Handle delivery type change
  const changeDeliveryType = (event) => {
    setDelType(event.target.value);
  };

  // Handle delivery slot change
  const changeDeliverySlot = (slotId) => {
    setDelSlot(slotId);
  };

  // Place order
  const placeOrder = async () => {
    if (selectedProductsList.length === 0) {
      showAlert("Please add items to your order");
      return;
    }
    
    showLoader();
    window.scrollTo(0, 0);
    
    // Get selected slot details
    const selectedSlot = allDeliverySlots.find(slot => slot.id === delSlot);
    
    // Get delivery charge
    const deliveryCharge = await getDeliveryCharge(delType);
    
    // Create an order object from the selected products
    let ordersObj = {};
    selectedProductsList.forEach(product => {
      ordersObj[product.id] = {
        ...product,
        count: product.quantity
      };
    });
    
    // Create order data
    let orderData = {
      userId: customerData.mobileNo,
      timeStamp: Date.now(),
      status: "pending",
      addressDetails: selectedAddress,
      totalCount: selectedProductsList.reduce((total, item) => total + item.quantity, 0),
      totalAmount: calculateTotalPrice(),
      paymentMode: "cod",
      customerId: customerData.customerId,
      deliveryDate: delDate,
      deliverySlot: delSlot,
      totalDiscount: 0,
      storeDetails: storeDetails,
      instructions: instructions,
      deliveryType: delType,
      shippingCost: delType === 'delivery' ? 39 : 0,
      platform: Capacitor.getPlatform(),
    };
    
    // Process order items
    let itemDetails = [];
    console.log("====",selectedProductsList);
    selectedProductsList.forEach(product => {
      itemDetails.push({
        ...product,
        count: product.quantity,
        name: product.name,
        price: product.price,
        id: product.id,
        urlId: product.urlId || product.id
      });
      
      // Log action for analytics
      logAction("PLACE_ORDER", product.urlId || product.id);
    });
    
    // Set order title to first product name
    orderData.orderTitle = selectedProductsList[0].name;
    
    // Add item details to order data
    orderData.itemDetails = itemDetails;
    
    try {
      // Create new order
      const response = await createNewOrder(orderData);
      
      // Navigate to order status page
      navigate("/orderStatus", {
        state: { ...response, orderData: orderData },
        replace: true,
      });
      
      showSnackbar("Order placed successfully!", "success");
    } catch (error) {
      console.error("Error placing order:", error);
      showAlert("Failed to place order. Please try again.");
    } finally {
      hideLoader();
    }
  };

  // Handle login
  const handleLogin = () => {
    if (enteredUsername === USERNAME && enteredPassword === PASSWORD) {
      setIsAuthenticated(true);
      setAuthError("");
      // Set session storage flag
      sessionStorage.setItem('isAuthenticated', 'true');
    } else {
      setAuthError("Invalid username or password");
    }
  };

  // Render login form
  const renderLoginForm = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Admin Login</Typography>
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        sx={styles.formField}
        value={enteredUsername}
        onChange={(e) => setEnteredUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        sx={styles.formField}
        value={enteredPassword}
        onChange={(e) => setEnteredPassword(e.target.value)}
      />
      {authError && (
        <Typography variant="body2" color="error" sx={{ marginBottom: '15px' }}>
          {authError}
        </Typography>
      )}
      <Button 
        variant="contained" 
        fullWidth
        sx={{ 
          background: '#a4243d', 
          color: 'white',
          '&:hover': {
            background: '#8a1e34',
          }
        }}
        onClick={handleLogin}
      >
        Login
      </Button>
    </Box>
  );

  // Render customer search form
  const renderCustomerSearchForm = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Enter Customer Mobile Number</Typography>
      <form onSubmit={submitCustomer(checkCustomer)}>
        <TextField
          label="Mobile Number"
          variant="outlined"
          fullWidth
          sx={styles.formField}
          {...registerCustomer("mobileNo", { 
            required: "Mobile number is required",
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit mobile number"
            }
          })}
          error={!!errorsCustomer.mobileNo}
          helperText={errorsCustomer.mobileNo?.message}
        />
        <Button 
          type="submit" 
          variant="contained" 
          fullWidth
          sx={{ 
            background: '#a4243d', 
            color: 'white',
            '&:hover': {
              background: '#8a1e34',
            }
          }}
        >
          Check Customer
        </Button>
      </form>
    </Box>
  );

  // Render customer registration form
  const renderRegistrationForm = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Register New Customer</Typography>
      <Typography variant="body2" sx={{ marginBottom: '15px' }}>
        Mobile Number: {customerMobile}
      </Typography>
      <form onSubmit={submitSignup(registerNewCustomer)}>
        <TextField
          label="Customer Full Name"
          variant="outlined"
          fullWidth
          sx={styles.formField}
          {...registerSignup("firstName", { required: "First name is required" })}
          error={!!errorsSignup.firstName}
          helperText={errorsSignup.firstName?.message}
        />
        <Button 
          type="submit" 
          variant="contained" 
          fullWidth
          sx={{ 
            background: '#a4243d', 
            color: 'white',
            '&:hover': {
              background: '#8a1e34',
            }
          }}
        >
          Register Customer
        </Button>
      </form>
    </Box>
  );

  // Render address form
  const renderAddressForm = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Add Delivery Address</Typography>
      <form onSubmit={submitAddress(addNewCustomerAddress)}>
        {isLoaded && (
          <>
            <Autocomplete onLoad={onAutoCompleteLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder="Search for your location"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `100%`,
                  height: `40px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  marginBottom: '20px',
                  outline: `none`,
                  textOverflow: `ellipses`
                }}
              />
            </Autocomplete>
            
            <Button 
              variant='outlined' 
              size='small' 
              onClick={() => printCurrentPosition()}
              sx={{ marginBottom: '15px' }}
            >
              Use Current Location
            </Button>
            
            <Box sx={{ marginBottom: '15px' }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
                center={window['currentLocation'] || latLong}
                options={{ mapTypeControl: false, fullscreenControl: false, minZoom: 15 }}
                onUnmount={onUnmount}
              >
                <Marker 
                  position={window['currentLocation'] || latLong} 
                  draggable={true} 
                  onDrag={(e) => mapPositionChanged(e.latLng)} 
                />
              </GoogleMap>
            </Box>
          </>
        )}
        
        <TextField
          label="House/Flat Number"
          variant="outlined"
          fullWidth
          sx={styles.formField}
          {...registerAddress("addressLine1", { required: "House/Flat details are required" })}
          error={!!errorsAddress.addressLine1}
          helperText={errorsAddress.addressLine1?.message}
        />
        <TextField
          label="Street/Area Details"
          variant="outlined"
          fullWidth
          sx={styles.formField}
          {...registerAddress("addressLine2", { required: "Street details are required" })}
          error={!!errorsAddress.addressLine2}
          helperText={errorsAddress.addressLine2?.message}
        />
        <TextField
          label="Landmark"
          variant="outlined"
          fullWidth
          sx={styles.formField}
          {...registerAddress("landmark")}
        />
        <TextField
          label="Pincode"
          variant="outlined"
          fullWidth
          sx={styles.formField}
          {...registerAddress("pincode", { 
            required: "Pincode is required",
            pattern: {
              value: /^[0-9]{6}$/,
              message: "Please enter a valid 6-digit pincode"
            }
          })}
          error={!!errorsAddress.pincode}
          helperText={errorsAddress.pincode?.message}
        />
        <Button 
          type="submit" 
          variant="contained" 
          fullWidth
          sx={{ 
            background: '#a4243d', 
            color: 'white',
            '&:hover': {
              background: '#8a1e34',
            }
          }}
        >
          Add Address
        </Button>
      </form>
    </Box>
  );

  // Render address list
  const renderAddressList = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Select Delivery Address</Typography>
      {addresses.length > 0 ? (
        addresses.map((address, index) => (
          <Paper 
            key={index} 
            elevation={selectedAddress === address ? 3 : 1}
            sx={{ 
              padding: '15px', 
              marginBottom: '15px', 
              cursor: 'pointer',
              border: selectedAddress === address ? '2px solid #a4243d' : 'none'
            }}
            onClick={() => selectAddress(address)}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
              {address.userName || 'Address'}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: '3px' }}>
              {address.houseDetails}, {address.streetDetails}
            </Typography>
            {address.landmark && (
              <Typography variant="body2" sx={{ marginBottom: '3px' }}>
                Landmark: {address.landmark}
              </Typography>
            )}
            <Typography variant="body2" sx={{ marginBottom: '3px' }}>
              Pincode: {address.pincode}
            </Typography>
            {address.storeDetails && (
              <Box sx={{ 
                marginTop: '10px', 
                padding: '8px', 
                backgroundColor: '#f9f9f9', 
                borderRadius: '4px',
                borderLeft: '3px solid #a4243d'
              }}>
                <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold', color: '#666' }}>
                    Store: {address.storeDetails.storeName}
                </Typography>
              </Box>
            )}
          </Paper>
        ))
      ) : (
        <Typography variant="body1" sx={{ textAlign: 'center', padding: '20px', color: '#666' }}>
          No saved addresses found
        </Typography>
      )}
      <Button 
        variant="outlined" 
        fullWidth
        sx={{ 
          borderColor: '#a4243d', 
          color: '#a4243d',
          marginTop: '10px',
          '&:hover': {
            borderColor: '#8a1e34',
            background: 'rgba(164, 36, 61, 0.04)',
          }
        }}
        onClick={() => setShowAddressForm(true)}
      >
        Add New Address
      </Button>
    </Box>
  );

  // Render delivery options
  const renderDeliveryOptions = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Delivery Options</Typography>
      
      {/* Delivery Date */}
      <FormControl component="fieldset" sx={styles.formField}>
        <FormLabel component="legend">Delivery Date</FormLabel>
        <RadioGroup
          row
          value={delDate}
          onChange={onDelDateChange}
        >
          <FormControlLabel value="Today" control={<Radio />} label="Today" />
          <FormControlLabel value="Tomorrow" control={<Radio />} label="Tomorrow" />
        </RadioGroup>
      </FormControl>
      
      {/* Delivery Type */}
      <FormControl component="fieldset" sx={styles.formField}>
        <FormLabel component="legend">Delivery Type</FormLabel>
        <RadioGroup
          row
          value={delType}
          onChange={changeDeliveryType}
        >
          <FormControlLabel value="delivery" control={<Radio />} label="Home Delivery" />
          <FormControlLabel value="pickup" control={<Radio />} label="Self Pickup" />
        </RadioGroup>
      </FormControl>
      
      {/* Delivery Slots */}
      <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
        Select Delivery Slot:
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '15px' }}>
        {filteredSlots.map((slot) => (
          <Button
            key={slot.id}
            variant={delSlot === slot.id ? "contained" : "outlined"}
            sx={{
              borderColor: '#a4243d',
              color: delSlot === slot.id ? 'white' : '#a4243d',
              background: delSlot === slot.id ? '#a4243d' : 'transparent',
              '&:hover': {
                background: delSlot === slot.id ? '#8a1e34' : 'rgba(164, 36, 61, 0.04)',
                borderColor: '#8a1e34',
              }
            }}
            onClick={() => changeDeliverySlot(slot.id)}
          >
            {slot.time}
          </Button>
        ))}
      </Box>
      
      {/* Special Instructions */}
      <TextField
        label="Special Instructions (Optional)"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        sx={styles.formField}
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
      />
      
      <Button 
        variant="contained" 
        fullWidth
        sx={{ 
          background: '#a4243d', 
          color: 'white',
          '&:hover': {
            background: '#8a1e34',
          }
        }}
        onClick={proceedToOrderSummary}
        disabled={!delSlot}
      >
        Proceed to Order Summary
      </Button>
    </Box>
  );

  // Render product selection
  const renderProductSelection = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Select Products</Typography>
      <Typography variant="body2" sx={{ marginBottom: '15px' }}>
        Customer: {customerData.f_name} {customerData.l_name} ({customerMobile})
      </Typography>
      
      {/* Product Selection */}
      {loadingProducts ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <ComponentLoader />
        </Box>
      ) : (
        <>
          <FormControl fullWidth sx={styles.formField}>
            <MUIAutocomplete
              id="product-select"
              options={allProducts}
              getOptionLabel={(option) => `${option.name} - ₹${option.price} (${option.qty})`}
              onChange={(event, newValue) => handleProductSelect(newValue)}
              value={selectedProduct}
              renderInput={(params) => <TextField {...params} label="Select Product" />}
            />
          </FormControl>
          
          {selectedProduct && (
            <Box sx={{ marginBottom: '15px' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                {selectedProduct.name} - ₹{selectedProduct.price}
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <Typography variant="body1" sx={{ marginRight: '15px' }}>
                  Quantity:
                </Typography>
                <Button 
                  variant="outlined" 
                  size="small"
                  sx={{ minWidth: '30px', padding: '0' }}
                  onClick={() => setProductQuantity(Math.max(1, productQuantity - 1))}
                >
                  -
                </Button>
                <Typography variant="body1" sx={{ margin: '0 10px' }}>
                  {productQuantity}
                </Typography>
                <Button 
                  variant="outlined" 
                  size="small"
                  sx={{ minWidth: '30px', padding: '0' }}
                  onClick={() => setProductQuantity(productQuantity + 1)}
                >
                  +
                </Button>
              </Box>
              
              <Button 
                variant="contained" 
                fullWidth
                sx={{ 
                  background: '#a4243d', 
                  color: 'white',
                  '&:hover': {
                    background: '#8a1e34',
                  }
                }}
                onClick={addProductToList}
              >
                Add to Order
              </Button>
            </Box>
          )}
        </>
      )}
      
      {/* Selected Products List */}
      {selectedProductsList.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Selected Products
          </Typography>
          
          {selectedProductsList.map((product) => (
            <Paper 
              key={product.id} 
              elevation={1}
              sx={{ 
                padding: '10px', 
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {product.name}
                </Typography>
                <Typography variant="body2">
                  Quantity: {product.quantity} × ₹{product.price} = ₹{product.quantity * product.price}
                </Typography>
              </Box>
              <Button 
                color="error"
                onClick={() => removeProductFromList(product.id)}
                sx={{ minWidth: '40px' }}
              >
                ×
              </Button>
            </Paper>
          ))}
          
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            padding: '10px', 
            marginTop: '10px',
            borderTop: '1px solid #eaeaea'
          }}>
            <Typography variant="subtitle1">
              Items Subtotal:
            </Typography>
            <Typography variant="subtitle1">
              ₹{selectedProductsList.reduce((total, product) => total + (product.price * product.quantity), 0)}
            </Typography>
          </Box>
          
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            padding: '10px'
          }}>
            <Typography variant="subtitle1">
              Delivery Charge:
            </Typography>
            <Typography variant="subtitle1">
              ₹{delType === 'delivery' ? 39 : 0}
            </Typography>
          </Box>
          
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            padding: '10px', 
            marginTop: '5px',
            borderTop: '1px solid #eaeaea',
            backgroundColor: '#f9f9f9'
          }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Total:
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              ₹{calculateTotalPrice()}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );

  // Render order summary
  const renderOrderSummary = () => (
    <Box sx={styles.shadowBox}>
      <Typography sx={styles.sectionTitle}>Order Summary</Typography>
      
      {/* Customer Details */}
      <Box sx={{ marginBottom: '15px' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Customer Details:
        </Typography>
        <Typography variant="body2">
          Name: {customerData.f_name} {customerData.l_name}
        </Typography>
        <Typography variant="body2">
          Mobile: {customerMobile}
        </Typography>
      </Box>
      
      {/* Delivery Details */}
      <Box sx={{ marginBottom: '15px' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Delivery Details:
        </Typography>
        <Typography variant="body2">
          Address: {selectedAddress.houseDetails}, {selectedAddress.streetDetails}
        </Typography>
        {selectedAddress.landmark && (
          <Typography variant="body2">
            Landmark: {selectedAddress.landmark}
          </Typography>
        )}
        <Typography variant="body2">
          Pincode: {selectedAddress.pincode}
        </Typography>
        <br />
        <Typography variant="body2">
          Delivery Date: {delDate}
        </Typography>
        <Typography variant="body2">
          Delivery Slot: {filteredSlots.find(slot => slot.id === delSlot)?.time || ''}
        </Typography>
        <br />
        <Typography variant="body2">
          Delivery Type: {delType === 'delivery' ? 'Home Delivery' : 'Self Pickup'}
        </Typography>
        {selectedAddress.storeDetails && (
          <Typography variant="body2">
            Store: {selectedAddress.storeDetails.storeName}
          </Typography>
        )}
        {instructions && (
          <Typography variant="body2">
            Special Instructions: {instructions}
          </Typography>
        )}
      </Box>
      
      {/* Payment Method */}
      <Box sx={{ marginBottom: '15px' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Payment Method:
        </Typography>
        <Typography variant="body2">
          Cash on Delivery
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '15px' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Instructions:
        </Typography>
        <Typography variant="body2">
          {instructions}
        </Typography>
      </Box>
      
      {/* Order Items */}
      <Box sx={{ marginBottom: '15px' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Order Items:
        </Typography>
        
        {selectedProductsList.map((product) => (
          <Paper 
            key={product.id} 
            elevation={1}
            sx={{ 
              padding: '10px', 
              marginBottom: '10px'
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {product.name}
            </Typography>
            <Typography variant="body2">
              Quantity: {product.quantity} × ₹{product.price} = ₹{product.quantity * product.price}
            </Typography>
          </Paper>
        ))}
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          padding: '10px', 
          marginTop: '10px',
          borderTop: '1px solid #eaeaea'
        }}>
          <Typography variant="subtitle1">
            Items Subtotal:
          </Typography>
          <Typography variant="subtitle1">
            ₹{selectedProductsList.reduce((total, product) => total + (product.price * product.quantity), 0)}
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          padding: '10px'
        }}>
          <Typography variant="subtitle1">
            Delivery Charge:
          </Typography>
          <Typography variant="subtitle1">
            ₹{delType === 'delivery' ? 39 : 0}
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          padding: '10px', 
          marginTop: '5px',
          borderTop: '1px solid #eaeaea',
          backgroundColor: '#f9f9f9'
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Total:
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            ₹{calculateTotalPrice()}
          </Typography>
        </Box>
      </Box>
      
      <Button 
        variant="contained" 
        fullWidth
        sx={{ 
          background: '#a4243d', 
          color: 'white',
          '&:hover': {
            background: '#8a1e34',
          }
        }}
        onClick={placeOrder}
      >
        Place Order
      </Button>
    </Box>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NavHeader title="Admin Order" />
      
      <Box sx={isDesktop ? styles.mainContDesk : styles.mainCont}>
        {loading ? (
          <ComponentLoader />
        ) : (
          !isAuthenticated ? renderLoginForm() : (
            <>
              {/* Step 1: Customer Search */}
              {renderCustomerSearchForm()}
              
              {/* Step 2: Customer Registration (if needed) */}
              {!customerExists && customerMobile && renderRegistrationForm()}
              
              {/* Step 3: Product Selection */}
              {showProductList && renderProductSelection()}
              
              {/* Step 4: Address Selection or Form */}
              {showProductList && showAddressForm && renderAddressForm()}
              {showProductList && showAddressList && renderAddressList()}
              
              {/* Step 5: Delivery Options */}
              {showDeliveryOptions && renderDeliveryOptions()}
              
              {/* Step 6: Order Summary */}
              {showOrderSummary && renderOrderSummary()}
            </>
          )
        )}
      </Box>
    </motion.div>
  );
}

export default AdminOrder; 