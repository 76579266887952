import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllBlogPosts, logAction } from '../services/api';
import ComponentLoader from '../components/ComponentLoader';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  CardActions,
  Button,
  Container,
  CardMedia
} from '@mui/material';
import NavHeader from '../components/NavHeader';
import { Helmet } from 'react-helmet';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function Blogs() {
  const [loading, setLoading] = useState(true);
  const [blogPosts, setBlogPosts] = useState([]);
  const navigate = useNavigate();

  const fetchBlogPosts = async () => {
    try {
      const posts = await getAllBlogPosts();
      setBlogPosts(posts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    logAction('VIEW_PUBLIC_BLOG_LIST');
    fetchBlogPosts();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  // Function to strip HTML tags and get plain text
  const stripHtml = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>?/gm, '');
  };

  const getSnippet = (post, maxLength = 120) => {
    // First check if the post has SEO description
    if (post.seo?.description) {
      return post.seo.description;
    }
    
    // Fallback to content
    const plainText = stripHtml(post.content || '');
    if (plainText.length <= maxLength) return plainText;
    return plainText.substring(0, maxLength) + '...';
  };
  
  // Get post title (SEO title if available, otherwise regular title)
  const getPostTitle = (post) => {
    return post.seo?.title || post.title;
  };
  
  // Get keywords from all posts for meta tags
  const getMetaKeywords = () => {
    const keywords = new Set();
    blogPosts.forEach(post => {
      if (post.seo?.keywords) {
        post.seo.keywords.split(',').forEach(kw => keywords.add(kw.trim()));
      }
    });
    return Array.from(keywords).join(', ');
  };

  // Estimate reading time (assuming average reading speed of 200 words per minute)
  const getReadingTime = (content) => {
    if (!content) return '1 min read';
    const plainText = stripHtml(content);
    const wordCount = plainText.split(/\s+/).length;
    const minutes = Math.max(1, Math.ceil(wordCount / 200));
    return `${minutes} min${minutes !== 1 ? 's' : ''} read`;
  };

  return (
    <Box sx={{ 
      padding: '4vw', 
      paddingTop: '12vh', 
      paddingBottom: '8vh',
      bgcolor: '#FFF9EB',  // Cream/beige background color
      minHeight: '100vh'
    }}>
      <Helmet>
        <title>Blog | Country Chicken Co</title>
        <meta name="description" content="Discover articles about country chicken, sustainable farming, health benefits of free-range poultry, and delicious recipes." />
        {blogPosts.length > 0 && (
          <meta name="keywords" content={getMetaKeywords()} />
        )}
      </Helmet>
      
      <NavHeader />
      
      <Container maxWidth="lg">
        {/* Centered title and subtitle */}
        <Box textAlign="center" mb={6}>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold', 
              fontSize: { xs: '3rem', md: '4rem' },
              color: '#000'
            }}
          >
            BLOGS
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              maxWidth: '800px', 
              margin: '0 auto',
              color: '#333',
              fontWeight: 'normal'
            }}
          >
            Welcome to the Country Chicken Co blogs! Explore insights on naturally 
            raised meats, healthy eating, and farm-fresh recipes.
          </Typography>
        </Box>

        {loading ? (
          <ComponentLoader />
        ) : blogPosts.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
            No blog posts available yet. Check back soon!
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {blogPosts.map((post) => (
              <Grid item xs={12} sm={6} key={post.id}>
                <Card 
                  elevation={1}
                  onClick={() => navigate(`/blogs/${post.urlId}`)}
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: 3
                    },
                    bgcolor: '#fff'
                  }}
                >
                  {post.coverImage ? (
                    <CardMedia
                      component="img"
                      height="240"
                      image={post.coverImage}
                      alt={getPostTitle(post)}
                      sx={{ objectFit: 'cover', height: '100%' }}
                    />
                  ) : (
                    <Box 
                      sx={{ 
                        height: 240, 
                        backgroundColor: 'grey.200', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        No image available
                      </Typography>
                    </Box>
                  )}
                  
                  <CardContent sx={{ flexGrow: 1, p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, color: 'text.secondary' }}>
                      <Typography variant="body2" component="span" color="text.secondary">
                        {formatDate(post.createdAt)}
                      </Typography>
                      <Box 
                        component="span" 
                        sx={{ 
                          mx: 1,
                          width: '4px',
                          height: '4px',
                          borderRadius: '50%',
                          backgroundColor: 'text.secondary',
                          display: 'inline-block'
                        }}
                      />
                      <Typography variant="body2" component="span" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                        {getReadingTime(post.content)}
                      </Typography>
                    </Box>
                    
                    <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                      {getPostTitle(post)}
                    </Typography>
                    
                    <Typography variant="body1" color="text.secondary">
                      {getSnippet(post)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

export default Blogs; 